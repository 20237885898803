import { createRenderer, RenderSender } from 'renderer/hooks/useRenderer';
import { Directory, FilesystemNode, NodeTypes, normalizePath, getDirectory } from 'common/filesystem';
import { Gltf, Material } from 'renderer/data/gltf';
import { Lockfile, ComponentField, Schema, Field, Variant } from 'renderer/data/tagged';
import { localstateManager } from 'renderer/hooks/useCacheState';
import { makeAutoObservable } from 'mobx';
import * as uuid from 'uuid';
import yaml from 'yaml';
import { groupBy, isEqual } from 'lodash';
import { Arcadefile } from 'renderer/utils/arcadefile';
import {
  AudioDefinition,
  AnimationDefinition,
  AnimationCollectionDefinition,
  FontDefinition,
  NavmeshDefinition,
  ModelDefinition,
  HdrDefinition,
  ParticleDefinition,
  TerrainDefinition,
  TextureDefinition,
  SpriteDefinition,
  HeightfieldDefinition,
  TrimeshDefinition,
  AnimationStateMachine,
  AnimationEdge,
  Message,
  AssetPack,
  Config,
  Position,
  Uuid,
  AnimationNodeId,
  AnimationEdgeId,
  AnimationStateMachineId,
  AnimationNode,
  BehaviorTree,
  BehaviorNodeId,
  ModelId,
  AnimationId,
  Planner,
  Particle as VfxParticle,
  Action as VfxAction,
  SpatialData,
  AudioGraph,
  AudioGraphDefinition,
  Particle,
} from '../../../dist/pkg/studio';
import { migrateLevel, migrateVfx } from './migrations';
//export { Particle as VfxParticle, Action as VfxAction } from '../../../dist/pkg/studio';

type ValueOf<T> = T[keyof T];

// Sync this with value in `gamefile.rs`
export const LVL_VERSION = 'v0.0.8';
// Sync this with value in `particles.rs`
export const VFX_VERSION = 'v0.0.1';

// Content browser
export const TERMINAL = 'terminal';
export const ASSETS = 'asset-browser';
export const ANIMATION_STATE = 'animation-state';
export const BEHAVIOR_TREE = 'behavior-tree';
export const PLANNER = 'planner';
export const AUDIO_GRAPH = 'audio-graph';

// Inspector
export const CONFIG = 'config';
export const COMMENTS = 'comments';
export const THREAD = 'thread';
export const ENTITY = 'entity';
export const ENTITIES = 'entities';
export const PLAYSESSIONS = 'playsessions';
export const ASSET_CONFIG = 'asset-config';

export const AnimationSymbol = 'Symbol.for("AnimationId")';
export const BehaviorTreeSymbol = 'Symbol.for("BehaviorTreeId")';
export const PlannerSymbol = 'Symbol.for("PlannerId")';

const datasets = {
  directories: localstateManager<string>(['settings', 'directories']),
  project: localstateManager<Project>(['settings', 'project']),
  level: localstateManager<number>(['settings', 'open-level']),
  accessToken: localstateManager<string>(['settings', 'access-token']),
};

export class Kph {
  public kilometers = 0.0;
  public hours = 0.0;

  static new(kilometers: number, hours: number): Kph {
    const kph = new Kph();
    kph.kilometers = kilometers;
    kph.hours = hours;
    return kph;
  }
}

export class Mps {
  public meters = 0.0;
  public seconds = 0.0;

  static new(meters: number, seconds: number): Mps {
    const mps = new Mps();
    mps.meters = meters;
    mps.seconds = seconds;
    return mps;
  }
}

const editorComponents = [
  'IdComponent',
  'TagComponent',
  'TransformComponent',
  'LightComponent',
  'SkyLightComponent',
  'CameraComponent',
  'TextComponent',
  'ParentComponent',
  'PrefabComponent',
  'InputComponent',
  'SkyLightComponent',
  'AudioListenerComponent',
  'ParticleComponent',
  'LightComponent',
  'PhysicsComponent',
  'ModelComponent',
  'StateMachineComponent',
  'DebugFrustumComponent',
  'TerrainComponent',
  'OccluderComponent',
  'OccludeeComponent',
  'SocketComponent',
  'SpriteComponent',
];

export class Radians {
  public radians = 0.0;

  static fromRadians(radians: number): Radians {
    const radian = new Radians();
    radian.radians = radians;
    return radian;
  }

  static fromDegrees(radians: number): Radians {
    const radian = new Radians();
    radian.radians = radians * (Math.PI / 180.0);
    return radian;
  }
}

export type Vec2 = [number, number];
export type Vec3 = [number, number, number];
export type Vec4 = [number, number, number, number];

export type Level = {
  version: string;
  config: Config;
  scene: Scene;
  filename: string;
};

export class Store {
  level: Level;
  editor: Editor;

  constructor(level: Level, editor: Editor) {
    this.editor = editor;
    this.level = level;
    makeAutoObservable(this);

    this.registerIpcEvents();
  }

  async registerIpcEvents() {
    if (!window.electron) {
      return;
    }

    this.editor.version = {
      editor: await window.electron.getVersion(),
    };

    window.electron.ipc.on('ugs:compress-image:errors', (args) => {
      console.error(args);
    });

    window.electron.ipc.on('update-available', () => {
      this.editor.updateState = UpdateState.DOWNLOADING;
    });

    window.electron.ipc.on('update-downloaded', () => {
      this.editor.updateState = UpdateState.UPDATE_AVAILABLE;
    });

    window.electron.ipc.on('ugs:play-game:start', (id: string, url: string): void => {
      this.editor.playing = id;
      window.electron.openWindow(id, url);
    });

    window.electron.ipc.on('ugs:game-closed', async (id: string) => {
      try {
        console.info('game closed', id);
        await window.game.closeGame(id);
      } catch (e) {
        console.error("Can't close game", e);
      }
      sendToLevel({ setRendering: true });
      this.editor.playing = null;
    });

    window.electron.ipc.on('ugs:export-level', async (_: any) => {
      const [exportDirectory, exportFilename] = await window.editor.saveFileDialog();
      if (!exportDirectory || !exportFilename) {
        return;
      }

      try {
        const projectDirectory = this.editor.project?.directory || '';
        const levelString = JSON.stringify(this.level);
        const modelFilesString = JSON.stringify(this.editor.modelFiles);

        await window.gltfTransform.write(
          exportFilename,
          exportDirectory,
          levelString,
          modelFilesString,
          projectDirectory
        );
      } catch (e) {
        console.error('error', e);
        return;
      }
    });

    window.electron.ipc.on('ugs:verify-asset-files', async (_: any) => {
      await this.verifyAssetFiles();
    });

    window.electron.ipc.on('ugs:verify-unused-assets', async (_: any) => {
      this.verifyUnusedAssets();
    });

    window.electron.ipc.on('ugs:compress-assets', async (_: any) => {
      await this.compressAssets();
    });

    window.electron.ipc.on('ugs:zip-assets', async (_: any) => {
      await this.zipAssets();
    });
  }

  async verifyAssetFiles() {
    const resourcesFolder = `${this.editor.project?.directory}/resources/`;

    let assetPaths: string[] = [];
    assetPaths = [...assetPaths, ...this.animations.map(([asset, _]) => asset.source)];
    assetPaths = [...assetPaths, ...this.models.map((asset) => asset.source)];
    assetPaths = [...assetPaths, ...this.sprites.map((asset) => asset.source)];
    assetPaths = [...assetPaths, ...this.audios.map((asset) => asset.source)];
    assetPaths = [...assetPaths, ...this.fonts.map((asset) => asset.source)];
    assetPaths = [...assetPaths, ...this.textures.map((asset) => asset.source)];
    assetPaths = [...assetPaths, ...this.navmeshes.map((asset) => asset.source)];
    assetPaths = [...assetPaths, ...this.particles.map((asset) => asset.source)];
    assetPaths = [...assetPaths, ...this.terrains.map((asset) => this.getTerrainImages(asset)).flat()];
    assetPaths = [
      ...assetPaths,
      ...this.heightfields.map((asset) => this.getHeightfieldImages(asset)).flat(),
    ];

    const notExisting = await window.fs.checkFiles(assetPaths.map((path) => resourcesFolder + path));

    if (notExisting.length === 0) {
      console.info('All assets exist on disk.');
    } else {
      console.error('The following asset files are missing:', notExisting);
    }
  }

  duplicateAnimationStateMachine(id: string) {
    const stateMachine = this.getAnimationStateMachine(id);
    if (!stateMachine) return;

    const newStateMachine = JSON.parse(JSON.stringify(stateMachine));
    newStateMachine.id = uuid.v4();
    newStateMachine.name = newStateMachine.name + ' new';

    this.saveAnimationStateMachine(newStateMachine);
    this.pushNewAnimationTab(newStateMachine.name, newStateMachine.id);
  }

  verifyUnusedAssets() {
    // NOTE: Typescript should be more dynamic than this. We should be able to iterate on
    //       `this.level.scene` and add everything except `prefabs`
    let assetIds: any = [];
    assetIds = [...assetIds, ...this.animations.map(([asset, _]) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.models.map((asset) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.sprites.map((asset) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.audios.map((asset) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.fonts.map((asset) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.textures.map((asset) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.navmeshes.map((asset) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.particles.map((asset) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.terrains.map((asset) => [asset.id, asset.source])];
    assetIds = [...assetIds, ...this.heightfields.map((asset) => [asset.id, asset.source])];

    const assetMap: { [id: string]: string } = {};
    for (const [id, source] of assetIds) {
      assetMap[id] = source;
    }

    const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
    const jsonLevel = JSON.stringify(this.level.scene.prefabs);
    const matches = jsonLevel.matchAll(uuidRegex);

    const assetUsages = new Set<string>();
    for (const match of matches) {
      assetUsages.add(match[0]);
    }

    // Exists in the asset list, but not used by any entity
    const unusedAssets: { [id: string]: string } = {};
    for (const [id, source] of assetIds) {
      if (!assetUsages.has(id)) {
        unusedAssets[id] = source;
      }
    }

    const unusedAssetsCount = Object.keys(unusedAssets).length;

    if (unusedAssetsCount === 0) {
      console.info('No assets are unused.');
    } else {
      console.error(`The following ${unusedAssetsCount} assets might be unused:`, unusedAssets);
    }
  }

  async compressAssets() {
    if (this.editor.compression) return;

    const modelRequests = new Set<ModelCompression>();
    const imageRequests = new Set<ImageCompression>();

    Object.values(this.editor.modelFiles)
      .map((model) => this.collectCompressionNeedsForMesh(this.editor.files[model.path], false))
      .forEach(({ uncompressedImages, uncompressedModels }) => {
        for (const request of uncompressedModels) {
          modelRequests.add(request);
        }
        for (const request of uncompressedImages) {
          imageRequests.add(request);
        }
      });

    const nodes = [...imageRequests, ...modelRequests] as CompressionRequest[];
    this.editor.compression = {
      current: 0,
      total: nodes.length,
      nodes,
    };

    for await (const _ of window.fs.compressImages(Array.from(imageRequests))) {
      this.bumpCompression();
    }

    for await (const _ of window.fs.packGltf(Array.from(modelRequests))) {
      this.bumpCompression();
    }

    delete this.editor.compression;
  }

  async zipAssets() {
    if (this.editor.zip) return;

    const nodes = Object.values(this.editor.files)
      .filter((file) => {
        return (
          file.type === NodeTypes.IMAGE ||
          file.type === NodeTypes.META ||
          file.type === NodeTypes.RAW ||
          file.type === NodeTypes.COMPRESSED ||
          file.type === NodeTypes.MODEL ||
          file.type === NodeTypes.HDR ||
          file.type === NodeTypes.MODEL_LOD ||
          file.type === NodeTypes.BINARY ||
          file.type === NodeTypes.PARTICLE ||
          file.type === NodeTypes.AUDIO ||
          file.type === NodeTypes.AUDIO_GRAPH ||
          file.type === NodeTypes.FONT
        );
      })
      .filter((file) => {
        const zip = this.editor.files[normalizePath(`${file.path}.zip`)];
        return !zip;
      })
      .map((file) => ({ ...file }));

    this.editor.zip = {
      current: 0,
      total: nodes.length,
      nodes,
    };

    for await (const _ of window.fs.zipFiles(Array.from(nodes))) {
      this.bumpZip();
    }

    /*
    const nodes = [...imageRequests, ...modelRequests] as CompressionRequest[];

    for await (const _ of Array.from(modelRequests).map((request) => window.fs.packGltf(request))) {
      this.bumpCompression();
    }
   */

    delete this.editor.zip;
  }

  receiveLevel(level: Level) {
    this.level.filename = level.filename;
    this.level.config = level.config;
    this.level.scene = level.scene;

    sendToLevel({ updateConfig: this.level.config });
    sendToLevel(createUpdateAssets(this.level.scene));
    sendToLevel(this.createEntitiesUpdate(this.prefabs));
  }

  duplicateEntity(entity: Prefab, withUpdate = true): Prefab {
    const parentships = this.iteratePrefabs(Object.values(this.level.scene.prefabs), entity.id);

    const parentEntity = this.duplicateSingleEntity(entity, withUpdate);

    for (const parentship of parentships) {
      const childEntity = this.duplicateEntity(parentship.prefab, withUpdate);

      const parent = getComponent('ParentComponent', childEntity);
      if (parent) {
        parent.ParentComponent.parent_id = parentEntity.id;
      }

      const socket = getComponent('SocketComponent', childEntity);
      if (socket) {
        socket.SocketComponent.parent_id = parentEntity.id;
      }

      this.updateEntity(childEntity, withUpdate);
    }

    return parentEntity;
  }

  duplicateSingleEntity(entity: Prefab, withUpdate = true): Prefab {
    const newName = this.getNextAvailableName(entity.tag.name);

    const newEntity = createDefaultEntity(uuid.v4(), newName, [0.0, 0.0, 0.0]);
    newEntity.transform = entity.transform;

    if (withUpdate) {
      sendToLevel({ newEntity: { prefab: newEntity, position: newEntity.transform.transform.translation } });
    }

    for (const component of entity.components) {
      const newComponent = this.duplicateComponent(component);
      newEntity.components.push(newComponent);
    }

    this.level.scene.prefabs[newEntity.id] = newEntity;

    if (withUpdate) {
      sendToLevel(this.createEntitiesUpdate([newEntity]));
    }

    return newEntity;
  }

  duplicateComponent(component: any): any {
    const componentName = Object.keys(component)[0];

    const componentSchema = this.querySchema(componentName);
    if (!componentSchema) {
      return;
    }

    // Deep copy. Only replace what's needed.
    const newComponent = JSON.parse(JSON.stringify(component));

    if (typeof component !== 'object') {
      return newComponent;
    }

    if (componentSchema.tag === 'Struct') {
      for (const field of componentSchema.fields) {
        const fieldName = field.name;

        if (field.ty.tag === 'Struct' || field.ty.tag === 'Enum') {
          const innerComponent = component[componentName][fieldName];
          const newInnerComponent = this.duplicateInnerComponent(innerComponent, field);

          newComponent[componentName][fieldName] = newInnerComponent;
          continue;
        }

        const anyTy = field.ty as any;
        const duplicateValue = eval(anyTy.duplicate_values);

        if (duplicateValue === null) {
          continue;
        }

        newComponent[componentName][fieldName] = duplicateValue;
      }
    } else if (componentSchema.tag === 'Enum') {
      for (const variant of componentSchema.variants) {
        const variantName = variant.name;
        const componentName = Object.keys(component)[0];
        const enumName = Object.keys(component[componentName])[0];

        if (variantName !== enumName) {
          continue;
        }

        if (variant.tag === 'Struct') {
          const innerComponent = component[componentName][variantName];

          const field: Field = {
            name: componentName,
            label: componentName,
            docs: null,
            component: null,
            ty: variant,
          };

          const newInnerComponent = this.duplicateInnerComponent(innerComponent, field);

          newComponent[componentName][enumName] = newInnerComponent;
          continue;
        }

        if ('duplicate_values' in variant) {
          const duplicateValue = eval(variant.duplicate_values);
          if (duplicateValue === null) {
            continue;
          }

          newComponent[componentName] = duplicateValue;
        }
      }
    }

    return newComponent;
  }

  duplicateInnerComponent(component: any, field: Field): any {
    const fieldSchema = field.ty;

    if (!fieldSchema || component == null) {
      return;
    }

    // Deep copy. Only replace what's needed.
    const newComponent = JSON.parse(JSON.stringify(component));
    if (typeof component !== 'object') {
      return newComponent;
    }

    if (fieldSchema.tag === 'Struct') {
      for (const field of fieldSchema.fields) {
        const fieldName = field.name;

        if (field.ty.tag === 'Struct' || field.ty.tag === 'Enum') {
          const innerComponent = component[fieldName];
          const newInnerComponent = this.duplicateInnerComponent(innerComponent, field);

          newComponent[fieldName] = newInnerComponent;
          continue;
        }

        const anyTy = field.ty as any;
        const duplicateValue = eval(anyTy.duplicate_values);

        if (duplicateValue === null) {
          continue;
        }

        newComponent[fieldName] = duplicateValue;
      }
    } else if (fieldSchema.tag === 'Enum') {
      for (const variant of fieldSchema.variants) {
        const variantName = variant.name;
        const componentName = Object.keys(component)[0];

        if (variantName !== componentName) {
          continue;
        }

        if (variant.tag === 'Struct') {
          const innerComponent = component[componentName];

          const field: Field = {
            name: componentName,
            label: componentName,
            docs: null,
            component: null,
            ty: variant,
          };

          const newInnerComponent = this.duplicateInnerComponent(innerComponent, field);

          newComponent[componentName] = newInnerComponent;
          continue;
        }

        if ('duplicate_values' in variant) {
          const duplicateValue = eval(variant.duplicate_values);
          if (duplicateValue === null) {
            continue;
          }

          newComponent[componentName] = duplicateValue;
        }
      }
    }

    return newComponent;
  }

  detachPrefabFromEntity(entity: Prefab) {
    const prefabComponent = getComponent('PrefabComponent', entity);
    if (!prefabComponent) {
      return;
    }

    const rootPrefabId = prefabComponent.PrefabComponent.id;
    const rootPrefab = this.level.scene.prefabs[rootPrefabId];

    entity = this.removeComponent(entity, 'PrefabComponent');

    for (const rootComponent of rootPrefab.components) {
      const newComponent = this.duplicateComponent(rootComponent);
      entity.components.push(newComponent);
    }

    this.updateEntity(entity);
  }

  resetLevel(level: Level) {
    sendToLevel('resetWorld');
    this.receiveLevel(level);
  }

  getAudioPlayer(id: string) {
    if (!this.editor.audioPlayers[id]) {
      this.editor.audioPlayers[id] = {
        id,
        playing: false,
        paused: false,
        looping: false,
      };
    }

    return this.editor.audioPlayers[id];
  }

  playAudio(id: string, volume: number = 1.0, spatial: SpatialData | null = null) {
    const player = this.getAudioPlayer(id);

    // If the engine receives a playAudio command it doesn't
    // restart existing ones.
    if (player.playing) return;

    player.playing = true;
    player.paused = false;
    sendToLevel({ playAudio: { track_id: id, volume, spatial } });
  }

  pauseAudio(audio_id: string) {
    const player = this.getAudioPlayer(audio_id);
    player.playing = false;
    player.paused = true;
    sendToLevel({ pauseAudio: { audio_id } });
  }

  unpauseAudio(audio_id: string) {
    const player = this.getAudioPlayer(audio_id);
    player.playing = true;
    player.paused = false;
    sendToLevel({ unpauseAudio: { audio_id } });
  }

  stopAudio(audio_id: string) {
    const player = this.getAudioPlayer(audio_id);
    player.playing = false;
    player.paused = false;
    sendToLevel({ stopAudio: { audio_id } });
  }

  getNextAvailableName(name: string): string {
    const regex = /^(.*?)( (\d+))?$/; // Cube, Cube 1, Cube 2, etc.
    const captures = regex.exec(name);

    if (!captures) {
      return name;
    }

    const baseName = captures[1];
    let index = captures[3] ? parseInt(captures[3]) : 0;

    outer: for (;;) {
      index += 1;
      const newName = `${baseName} ${index}`;

      for (const prefab of this.prefabs) {
        if (prefab.tag.name === newName) {
          continue outer;
        }
      }

      return newName;
    }
  }

  async addFiles(files: FilesystemNode[]) {
    const allFiles: Files = {};
    const levels = [];
    const textures: Textures = {};
    const models: ModelFiles = {};
    const materials: MaterialTextures = {};
    const audioGraphs: { [index: string]: Draft<AudioGraph> } = {};

    const deferred: (() => Promise<void>)[] = [];

    for (const file of files) {
      // NOTE: This is a bit hacky. Not because it doesn't work, but because
      // it's brittle.
      file.path = normalizePath(file.path);

      const assetsFolder = this.editor.arcadefile?.build.client.assets ?? '';
      if (file.path.includes(assetsFolder)) {
        file.path = file.path.replace(assetsFolder, '');
        file.isResource = true;
        //const relative = path.relative(assetsFolder, file.path);
        //console.log(relative);
      }

      allFiles[file.path] = file;
    }

    this.editor.files = { ...this.editor.files, ...allFiles };

    for (const file of files) {
      if (file.type === NodeTypes.LOCKFILE) {
        if (file.path.includes('server')) {
          const content = await window.fs.readFile(file.full);
          const data = JSON.parse(content) as Lockfile;
          this.editor.lockfiles.server = data;
        }
        if (file.path.includes('client')) {
          const content = await window.fs.readFile(file.full);
          const data = JSON.parse(content) as Lockfile;
          this.editor.lockfiles.client = data;
        }
      } else if (file.type === NodeTypes.SCENE) {
        const content = await window.fs.readFile(file.full);
        const data = await migrateLevel(JSON.parse(content));

        const levelFile = {
          id: data.config.id,
          location: file.full,
          path: file.path,
          name: data.config.name,
        };

        levels.push(levelFile);
      } else if (file.type === NodeTypes.IMAGE) {
        if (!this.editor.textures[file.path]) {
          const texture = {
            id: uuid.v4(),
            source: file.path,
            isNormalMap: false,
          };
          textures[texture.source] = texture;
        }
      } else if (file.type === NodeTypes.MODEL) {
        deferred.push(async (): Promise<void> => {
          const content = await window.fs.readFile(file.full);
          if (!content) {
            console.error('Unable to load file:', file.full);
            return;
          }
          const data = JSON.parse(content) as Gltf;

          const lods = (await Promise.all([window.fs.readFile(`${file.full}.0.lod`)]))
            .filter((json) => json !== undefined)
            .map((json) => JSON.parse(json));

          const modelFile = {
            path: file.path,
            full: file.full,
            gltf: data,
            lods,
          };

          models[modelFile.path] = modelFile;

          if (data.materials) {
            const directory = getDirectory(file.path);
            for (const material of data.materials) {
              if (material.normalTexture) {
                const textureIndex = material.normalTexture.index;
                const imageIndex = data.textures?.[textureIndex].source ?? 0;
                let path = decodeURIComponent(data.images?.[imageIndex].uri ?? 'unknown');
                path = normalizePath(`${directory}/${path}`);
                if (!this.editor.textures[path]) {
                  const texture = {
                    id: uuid.v4(),
                    source: path,
                    isNormalMap: true,
                  };
                  textures[texture.source] = texture;
                } else {
                  this.editor.textures[path].isNormalMap = true;
                }
                materials[path] = {
                  name: material.name,
                  model: modelFile.path,
                  isNormalMap: true,
                  isRoughnessMetallic: false,
                };
              }
              if (material.emissiveTexture) {
                const textureIndex = material.emissiveTexture.index;
                const imageIndex = data.textures?.[textureIndex].source ?? 0;
                let path = decodeURIComponent(data.images?.[imageIndex].uri ?? 'unknown');
                path = normalizePath(`${directory}/${path}`);
                materials[path] = {
                  name: material.name,
                  model: modelFile.path,
                  isNormalMap: false,
                  isRoughnessMetallic: false,
                };
              }
              if (material.pbrMetallicRoughness?.metallicRoughnessTexture) {
                const textureIndex = material.pbrMetallicRoughness.metallicRoughnessTexture.index;
                const imageIndex = data.textures?.[textureIndex].source ?? 0;
                let path = decodeURIComponent(data.images?.[imageIndex].uri ?? 'unknown');
                path = normalizePath(`${directory}/${path}`);
                materials[path] = {
                  name: material.name,
                  model: modelFile.path,
                  isNormalMap: false,
                  isRoughnessMetallic: true,
                };
              }
              if (material.pbrMetallicRoughness?.baseColorTexture) {
                const textureIndex = material.pbrMetallicRoughness.baseColorTexture.index;
                const imageIndex = data.textures?.[textureIndex].source ?? 0;
                let path = decodeURIComponent(data.images?.[imageIndex].uri ?? 'unknown');
                path = normalizePath(`${directory}/${path}`);
                materials[path] = {
                  name: material.name,
                  model: modelFile.path,
                  isNormalMap: false,
                  isRoughnessMetallic: false,
                };
              }
            }
          }
        });
      } else if (file.type === NodeTypes.AUDIO_GRAPH) {
        deferred.push(async (): Promise<void> => {
          const graph: AudioGraph = JSON.parse(await window.fs.readFile(file.full));
          audioGraphs[file.path] = new Draft(graph, file.extension, file.path, file.full);
        });
      }
    }

    this.editor.levels = [...this.editor.levels, ...levels];
    this.editor.textures = { ...this.editor.textures, ...textures };

    setTimeout(async () => {
      for (const defer of deferred) {
        await defer();
      }
      this.editor.modelFiles = { ...this.editor.modelFiles, ...models };
      this.editor.materialTextures = { ...this.editor.materialTextures, ...materials };
      this.editor.audioGraphs = { ...this.editor.audioGraphs, ...audioGraphs };
    }, 1000);
  }

  isTexturePartOfMaterial(path: string): boolean {
    return !!this.editor.materialTextures[path];
  }

  getTerrainTilePath(path: string): TerrainTilePath | null {
    const regex = /terrain\.([a-zA-Z_-]+)\.(albedo|normals|height|grass)\.x([0-9]+)\.y([0-9]+)/g;
    const search = [...path.matchAll(regex)];
    if (search.length == 0) return null;

    return {
      path,
      name: search[0][1],
      type: search[0][2] as TerrainType,
      x: +search[0][3],
      y: +search[0][4],
    };
  }

  closeVfx() {
    this.editor.vfx = undefined;
  }

  async createVfx(): Promise<'ok' | 'no-compile'> {
    if (!this.editor.lockfiles.server?.action) return 'no-compile';

    const schema = this.getDefaultValue(this.editor.lockfiles.server.action);
    const action = {
      ...schema.Action,
      id: uuid.v4(),
      name: 'unknown',
    };

    const vfx = {
      selectedAction: undefined,
      item: {
        id: uuid.v4(),
        name: 'Unknown',
        actions: [action],
      },
    };

    this.editor.vfx = vfx;

    const update = createUpdateVfx(this.level.scene);
    sendToVfx(update);
    sendToVfx({ setVfxMode: vfx.item.id });
    sendToVfx({ updateConfig: this.level.config });

    return 'ok';
  }

  async saveVfx() {
    if (!this.editor.project?.directory) return;
    if (this.editor.openLevel === null || this.editor.openLevel === undefined) return;
    if (this.editor.levels.length <= 0) return;
    if (!this.editor.vfx) return;

    const vfx = this.editor.vfx.item;
    let location;
    if (!this.editor.vfx.filename) {
      location = await window.fs.saveVfxDialog();
      this.editor.vfx.filename = location.replace(this.editor.project.directory + '/resources', '');
    } else {
      location = `${this.editor.project.directory}/resources/${this.editor.vfx.filename}`;
    }

    const content = JSON.stringify(vfx, null, 2);
    await window.fs.saveFile(location, content);
  }

  async selectVfx(asset: FilesystemNode) {
    if (asset.type !== NodeTypes.PARTICLE) return;

    const data = JSON.parse(await window.fs.readFile(asset.full)) as VfxParticle;
    const vfx = await migrateVfx(data);

    this.editor.vfx = {
      item: vfx,
      filename: asset.path,
    };

    const update = createUpdateVfx(this.level.scene);
    //console.log(update);
    sendToVfx(update);
    sendToVfx({ setVfxMode: vfx.id });
    sendToVfx({ updateConfig: this.level.config });
  }

  pushNewVfxAction() {
    if (!this.editor.vfx) return;
    if (!this.editor.lockfiles.server?.action) return;

    const action = {
      ...this.getDefaultValue(this.editor.lockfiles.server.action).Action,
      id: uuid.v4(),
      name: 'unknown',
    };
    this.editor.vfx.item.actions.push(action);
  }

  selectVfxAction(id?: string) {
    if (!this.editor.vfx) return;

    this.editor.vfx.selectedAction = id;
  }

  get playSession(): string | undefined {
    return this.editor.playSession;
  }

  get vfx(): VfxEditor | undefined {
    return this.editor.vfx;
  }

  get selectedAction(): VfxAction | undefined {
    if (!this.editor.vfx) return;
    const action = this.editor.vfx.selectedAction;
    if (!action) return;

    return this.editor.vfx.item.actions.find((item) => item.id === action);
  }

  get selectedActionIndex(): number | undefined {
    if (!this.editor.vfx) return;
    const action = this.editor.vfx.selectedAction;
    if (!action) return;
    const index = this.editor.vfx.item.actions.findIndex((item) => item.id === action);
    if (index < 0) return;
    return index;
  }

  updateAction(action: VfxAction) {
    if (!this.editor.vfx) return;
    const selection = this.editor.vfx.selectedAction;
    if (!action) return;
    const index = this.editor.vfx.item.actions.findIndex((item) => item.id === selection);

    if (index < 0) return;
    this.editor.vfx.item.actions[index] = action;
    sendToVfx({ updateParticle: this.editor.vfx.item });
  }

  selectPlaySession(id?: string) {
    this.editor.playSession = id;
  }

  getMaterialInfo(path: string): MaterialInfo {
    return this.editor.materialTextures[path];
  }

  checkIfProjectIsLocal(gameToCheck: Game): boolean {
    return !!this.getLocalUrl(gameToCheck);
  }

  getLocalUrl(gameToCheck: Game): string | null {
    return datasets.directories.get([gameToCheck.id]);
  }

  async loadProject(gameToLoad: Game) {
    const cachedLocationData = datasets.directories.get([gameToLoad.id]);

    if (!cachedLocationData) {
      const [location, arcadefile] = await window.game.openGame();

      if (!location) throw Error('Could not open project');

      this.editor.arcadefile = yaml.parse(arcadefile) as Arcadefile;

      const project = {
        id: gameToLoad.id,
        name: gameToLoad.name,
        directory: location,
      };
      this.editor.project = project;
      // NOTE: I don't remember why we need both here
      datasets.directories.set(location, gameToLoad.id);
      datasets.project.set(project);
    } else {
      const project = {
        id: gameToLoad.id,
        name: gameToLoad.name,
        directory: cachedLocationData,
      };
      this.editor.project = project;
      datasets.project.set(project);
    }
    //window.location.pathname = await window.fs.resolveHtmlPath('/editor.html');
    //await window.fs.getHtmlPath('index.html');
  }

  removeFiles(files: FilesystemNode[]) {
    for (const curr of files) {
      curr.path = normalizePath(curr.path);
      delete this.editor.files[curr.path];
    }
  }

  parentEntity(child: Prefab, parent?: Prefab, withUpdate = true) {
    const child_id = child.id;

    if (!parent) {
      const filtered = this.level.scene.prefabs[child_id].components.filter(
        (component) => 'ParentComponent' in component
      );
      this.level.scene.prefabs[child_id].components = filtered;
      if (withUpdate) sendToLevel({ parentEntity: { parent_id: null, child_id } });
    } else {
      const parent_id = parent.id;
      this.level.scene.prefabs[child_id].components.push({
        ParentComponent: {
          parent_id: parent_id,
        },
      });
      if (withUpdate) sendToLevel({ parentEntity: { parent_id, child_id } });
    }
  }

  deleteCurrentEntity() {
    const entityId = this.editor.selectedEntity;
    if (!entityId) return;
    this.selectEntity(null);
    this.deleteEntity(entityId);
  }

  mergeEntities(prev: Prefab, next: Prefab): Prefab {
    const components: { [key: string]: any } = {};
    prev.components.forEach((item) => {
      const [key] = Object.keys(item);
      components[key] = item;
    });
    next.components.forEach((item) => {
      const [key] = Object.keys(item);
      components[key] = item;
    });

    return { ...next, components: Object.values(components) };
  }

  filterComponents(entity: Prefab): Prefab {
    const filteredComponents = entity.components.filter((item) => {
      const [key] = Object.keys(item);
      return editorComponents.includes(key);
    });
    return { ...entity, components: filteredComponents };
  }

  // Removes a component and updates the renderer
  removeComponent(entity: Prefab, componentName: string): Prefab {
    const components = entity.components.filter((component) => !(componentName in component));

    const data = {
      ...entity,
      components,
    };

    const id = entity.id;
    this.level.scene.prefabs[id] = data;
    sendToLevel({ removeComponent: { prefab_id: entity.id, component_name: componentName } });

    return this.level.scene.prefabs[id];
  }

  updateEntity(entity: Prefab, withUpdate = true) {
    const id = entity.id;
    const currentEntity = this.level.scene.prefabs[id];
    this.level.scene.prefabs[id] = this.mergeEntities(currentEntity, entity);

    if (withUpdate) {
      sendToLevel({ updateEntity: this.filterComponents(entity) });
    }
  }

  setDocs(subject: string, content: string) {
    this.editor.docs = {
      subject,
      content,
    };
  }

  dismissDocs() {
    this.editor.docs = undefined;
  }

  createNewEntity(withUpdate = true): Prefab {
    const id = uuid.v4();
    const position: Vec3 = [0.0, 0.0, 0.0];
    const prefab = createDefaultEntity(id, 'Temp', position);
    this.level.scene.prefabs[id] = prefab;
    if (withUpdate) sendToLevel({ newEntity: { prefab, position } });

    return prefab;
  }

  deleteEntity(id: string, withUpdate = true) {
    const parentships = this.iteratePrefabs(Object.values(this.level.scene.prefabs), id);

    for (const parentship of parentships) {
      this.deleteEntity(parentship.prefab.id, withUpdate);
    }

    this.deleteSingleEntity(id, withUpdate);
  }

  deleteSingleEntity(id: string, withUpdate = true) {
    delete this.level.scene.prefabs[id];
    if (withUpdate) sendToLevel({ deleteEntity: id });
  }

  selectEntity(entity: Prefab | null) {
    const id = entity?.id ?? null;
    this.editor.selectedEntity = id;

    const panel = this.editor.inspector.panels.find((panel) => panel.type === ENTITY);
    if (panel) this.editor.inspector.selected = panel.id;

    sendToLevel({ selectEntity: id });
  }

  isEntityHighlighted(prefab: Prefab): boolean {
    const entity = this.selectedEntity;
    return prefab.id === entity?.id || this.editor.highlightedEntities.includes(prefab.id);
  }

  highlightEntity(id: string, withUpdate = true) {
    if (this.editor.highlightedEntities.includes(id)) return;
    this.editor.highlightedEntities.push(id);
    if (withUpdate) sendToLevel({ highlightEntity: id });
  }

  unhighlightEntity(id: string, withUpdate = true) {
    if (!this.editor.highlightedEntities.includes(id)) return;
    const filtered = this.editor.highlightedEntities.filter((entityId) => id !== entityId);
    this.editor.highlightedEntities = filtered;
    if (withUpdate) sendToLevel({ unhighlightEntity: id });
  }

  pushAndSelectPanel(panel: Panel) {
    const id = panel.id;
    this.editor.contentBrowser.panels.push(panel);
    this.editor.contentBrowser.selected = id;
  }

  async playGame() {
    if (!this.editor.project?.directory) {
      return;
    }

    const sessionId = uuid.v4();

    this.pushAndSelectPanel({
      id: sessionId,
      label: 'Compilation',
      type: TERMINAL,
      protected: true,
    });

    this.editor.cli[sessionId] = {
      id: sessionId,
      content: [],
    };

    const arcadeFilePath = `${this.editor.project.directory}/Arcadefile`;
    let levelFilePath = this.editor.levels[this.editor.openLevel ?? 0].location;
    levelFilePath = levelFilePath.replace(this.editor.project.directory, '');
    levelFilePath = levelFilePath.replace('/resources/', '');
    const accessToken = datasets.accessToken.get();

    if (!accessToken) return;
    if (!this.editor.arcadefile) return;

    await window.game.playGame(
      process.env.REACT_APP_MEDIA_WS_URL ?? 'wss://media.ultimate.games',
      this.editor.arcadefile.id,
      sessionId,
      accessToken,
      arcadeFilePath,
      levelFilePath,
      (data) => {
        this.editor.cli[sessionId].content.push(data);
      }
    );

    this.markTabUnprotected(sessionId);
    sendToLevel({ setRendering: false });
  }

  async stopGame() {
    if (!this.editor.project?.directory) {
      return;
    }

    if (!this.editor.playing) {
      return;
    }

    try {
      await window.game.closeGame(this.editor.playing);
    } catch (e) {
      console.error("Can't close game", e);
    }
    this.editor.playing = null;
  }

  getCliMessages(id: string, listener: (data: string) => void) {
    const data = this.editor.cli[id];
    if (!data) return;
    window.game.listenChannel(id, (line) => {
      listener(line);
    });
  }

  markTabUnprotected(id: string) {
    const tab = this.editor.contentBrowser.panels.find((item) => item.id === id);
    if (!tab) return;
    tab.protected = false;
  }

  selectAsset(asset: FilesystemNode | null) {
    this.editor.selectedAsset = asset;
  }

  async save() {
    if (!this.editor.project?.directory) return;
    if (this.editor.openLevel === null || this.editor.openLevel === undefined) return;
    if (this.editor.levels.length <= 0) return;

    const scene = this.editor.levels[this.editor.openLevel];
    const filename = scene.location;
    const content = JSON.stringify(this.level, null, 2);
    await window.fs.saveFile(filename, content);

    await Promise.all(
      Object.values(this.editor.audioGraphs)
        .filter((draft) => draft.dirty)
        .map((draft) => draft.save())
    );
  }

  get project(): Project | undefined {
    return this.editor.project;
  }

  get playing(): boolean {
    return !!this.editor.playing;
  }

  get contentPanels(): Panel[] {
    return this.editor.contentBrowser.panels;
  }

  get outline(): Panel[] {
    return this.editor.outline.panels;
  }

  get inspector(): Panel[] {
    return this.editor.inspector.panels;
  }

  get editorVersion(): string {
    const version = this.editor.version?.editor;
    return version ? `v${version}` : 'unknown';
  }

  get docs(): Docs | undefined | null {
    return this.editor.docs;
  }

  get selectedModel(): ModelDefinition | null {
    const entity = this.selectedEntity;
    const model = entity?.components.find((component) => component.ModelComponent?.id);
    if (!model) return null;
    return this.level.scene.models[model.id];
  }

  get trimeshes(): TrimeshDefinition[] {
    return Object.values(this.level.scene.trimeshes);
  }

  get particles(): ParticleDefinition[] {
    return Object.values(this.level.scene.particles);
  }

  get animations(): AnimationConfig[] {
    const collections = Object.values(this.level.scene.animations).map((collection): AnimationConfig[] => {
      return collection.definitions.map((definition) => {
        return [collection, definition];
      });
    });

    return collections.flat();
  }

  get terrains(): TerrainDefinition[] {
    return Object.values(this.level.scene.terrains);
  }

  get heightfields(): HeightfieldDefinition[] {
    return Object.values(this.level.scene.heightfields ?? {});
  }

  get models(): ModelDefinition[] {
    return Object.values(this.level.scene.models);
  }

  get behavior_trees(): BehaviorTree[] {
    return Object.values(this.level.scene.behavior_tree ?? {});
  }

  get fonts(): FontDefinition[] {
    return Object.values(this.level.scene.fonts ?? {});
  }

  get navmeshes(): NavmeshDefinition[] {
    return Object.values(this.level.scene.navmeshes ?? {});
  }

  get audios(): SpriteDefinition[] {
    return Object.values(this.level.scene.audio ?? {});
  }

  get sprites(): SpriteDefinition[] {
    return Object.values(this.level.scene.sprites ?? {});
  }

  get textures(): TextureDefinition[] {
    return Object.values(this.level.scene.textures ?? {});
  }

  get planners(): Planner[] {
    return Object.values(this.level.scene.planners ?? {});
  }

  get machines(): AnimationStateMachine[] {
    return Object.values(this.level.scene.animation_state ?? {});
  }

  get audioTracks(): AudioDefinition[] {
    return Object.values(this.level.scene.audio ?? {});
  }

  get backgrounds(): DynamicBackground[] {
    return Object.values(this.level.scene.dynamic_backgrounds);
  }

  get hdrs(): HdrDefinition[] {
    return Object.values(this.level.scene.hdrs);
  }

  get config(): Config {
    return this.level.config;
  }

  get selectedBehaviorTreeNode(): BehaviorNodeId | null {
    return this.editor.behavior.selectedNode;
  }

  get selectedAnimationNode(): AnimationNodeId | null {
    return this.editor.animationState.selectedNode;
  }

  get selectedAnimationEdge(): AnimationEdgeId | null {
    return this.editor.animationState.selectedEdge;
  }

  querySchema(key: string): ComponentField | null {
    const schema = Object.fromEntries(
      (this.editor.lockfiles.server?.components ?? []).map((item) => [item.name, item])
    );
    return schema[key];
  }

  queryInnerSchema(key: string, parentSchema: Field): Field | Variant | null {
    if (parentSchema.ty.tag === 'Struct') {
      return parentSchema.ty.fields.find((item) => item.name === key) || null;
    } else if (parentSchema.ty.tag === 'Enum') {
      return parentSchema.ty.variants.find((item) => item.name === key) || null;
    }

    return null;
  }

  queryTransitionSchema(key: string): ComponentField | null {
    const transitions = this.editor.lockfiles.server?.animations ?? [];
    const schema = Object.fromEntries(transitions.map((item) => [item.name, item]));
    return schema[key];
  }

  selectBehaviorTreeNode(nodeId: BehaviorNodeId | null) {
    this.editor.behavior.selectedNode = nodeId;
    //sendToLevel({ selectAnimationNode: [machineId, animationId] });
  }

  selectAnimationNode(machineId: AnimationStateMachineId, animationId: AnimationNodeId | null) {
    this.editor.animationState.selectedNode = animationId;
    sendToLevel({ selectAnimationNode: [machineId, animationId] });
  }

  selectAnimationEdge(_: AnimationStateMachineId, animationId: AnimationEdgeId | null) {
    this.editor.animationState.selectedEdge = animationId;
    //sendToLevel({ selectAnimationNode: [machineId, animationId] });
  }

  iteratePrefabs(prefabs: Prefab[], filter?: string): Parentship[] {
    const potentialChildren = [];
    const filtered = [];

    for (const prefab of prefabs) {
      const parent = getComponent('ParentComponent', prefab);
      const socket = getComponent('SocketComponent', prefab);

      if (
        filter === undefined &&
        parent?.ParentComponent?.parent_id === undefined &&
        socket?.SocketComponent?.parent_id === undefined
      ) {
        filtered.push(prefab);
      } else if (parent !== undefined && parent.ParentComponent.parent_id === filter) {
        filtered.push(prefab);
      } else if (socket !== undefined && socket.SocketComponent.parent_id === filter) {
        filtered.push(prefab);
      } else {
        potentialChildren.push(prefab);
      }
    }

    const parents: Parentship[] = [];
    for (const entity of filtered) {
      const children = this.iteratePrefabs(potentialChildren, entity.id);
      parents.push({
        prefab: entity,
        children,
      });
    }

    return parents;
  }

  get customComponents(): ComponentField[] {
    const components = this.editor.lockfiles.server?.components ?? [];
    const filtered = components.filter((component) => !component.private);

    if (!this.editor.search.context.includes('entity')) {
      return filtered;
    }

    if (this.editor.search.name === '') {
      return filtered;
    }

    const toSearch = this.editor.search.name.toLowerCase();
    return filtered.filter((component) => component.label.toLowerCase().includes(toSearch));
  }

  get parentships(): Parentship[] {
    let parentships = this.iteratePrefabs(Object.values(this.level.scene.prefabs));

    if (!this.editor.search.context.includes('outline')) {
      return parentships;
    }

    if (this.editor.search.name.length > 0) {
      const toSearch = this.editor.search.name.toLowerCase();
      parentships = parentships.filter((parentship) =>
        parentship.prefab.tag.name.toLowerCase().includes(toSearch)
      );
    }

    // TODO(Pedro): This is a bit of a mess.
    // Needs to be redone to use some sort of functional programming for pattern matching.
    if (this.editor.search.withComponents.length > 0) {
      parentships = parentships.filter((parentship) => {
        return this.editor.search.withComponents.some((withComponents) => {
          return withComponents.every((withComponent) => {
            return parentship.prefab.components.some((component) => {
              const componentName = Object.keys(component)[0];
              return componentName.toLowerCase().includes(withComponent.toLowerCase());
            });
          });
        });
      });
    }

    if (this.editor.search.withoutComponents.length > 0) {
      parentships = parentships.filter((parentship) => {
        return this.editor.search.withoutComponents.every((withoutComponents) => {
          return withoutComponents.every((withoutComponent) => {
            return parentship.prefab.components.every((component) => {
              const componentName = Object.keys(component)[0];
              return !componentName.toLowerCase().includes(withoutComponent.toLowerCase());
            });
          });
        });
      });
    }

    return parentships;
  }

  get prefabs(): Prefab[] {
    return Object.values(this.level.scene.prefabs);
  }

  get selectedEntity(): Prefab | null {
    if (!this.editor.selectedEntity) return null;
    return this.level.scene.prefabs?.[this.editor.selectedEntity] ?? null;
  }

  get selectedAsset(): FilesystemNode | null {
    return this.editor.selectedAsset;
  }

  get tree(): Directory | null {
    if (!this.editor.project?.directory) return null;

    const directory = new Directory(this.editor.project?.directory);
    for (const [, file] of Object.entries(this.editor.files)) {
      directory.insert(file);
    }

    return directory;
  }

  get assets(): FilesystemNode[] {
    return Object.values(this.editor.files);
  }

  getModelFile(mesh: FilesystemNode): ModelFile | undefined {
    if (mesh.type !== NodeTypes.MODEL) return undefined;

    return this.editor.modelFiles?.[mesh.path];
  }

  getGltf(mesh: FilesystemNode): Gltf | undefined {
    if (mesh.type !== NodeTypes.MODEL) return undefined;

    return this.editor.modelFiles?.[mesh.path]?.gltf;
  }

  getGltfByModelId(id: ModelId | null, lod = 0): Gltf | undefined {
    if (!id) return undefined;

    const model = this.level.scene.models[id];

    if (!model) return undefined;

    const modelFile = this.editor.modelFiles[model.source];

    return modelFile.lods?.[lod] ?? modelFile.gltf;
  }

  getGltfByAnimationId(id: AnimationId): Gltf | undefined {
    const animation = this.level.scene.animations[id];

    if (!animation) return undefined;

    return this.editor.modelFiles[animation.source].gltf;
  }

  getModel(asset: FilesystemNode): ModelDefinition | undefined {
    return Object.values(this.level.scene.models).find((item) => item.source === asset.path);
  }

  getMaterials(file: FilesystemNode): Material[] | undefined {
    if (file.type !== NodeTypes.MODEL) return undefined;

    return this.editor.modelFiles?.[file.path]?.gltf?.materials;
  }

  // getTexture(file: FilesystemNode): Texture[] | undefined {
  //   if (file.type !== NodeTypes.IMAGE) return undefined;
  //   return this.editor.textures
  // }

  isTerrainCompressed(terrain: FilesystemNode): boolean {
    if (!this.editor.project?.directory) return false;
    if (terrain.type !== NodeTypes.TERRAIN) return false;

    const images = this.getTerrainImages(terrain);

    if (images.length === 0) return true;

    for (const image of images) {
      const ktxPath = normalizePath(`${image}.ktx`);
      const ktx = this.editor.files[ktxPath];
      if (!ktx) {
        return false;
      }
    }

    if (!this.isHeightfieldProcessed(terrain)) {
      return false;
    }

    return true;
  }

  async compressTerrain(terrain: FilesystemNode) {
    if (this.editor.compression) return; // compression already in progress
    if (!this.editor.project?.directory) return;
    if (terrain.type !== NodeTypes.TERRAIN) return;

    const uncompressed: ImageCompression[] = [];

    const images = this.getTerrainImages(terrain);
    for (const image of images) {
      const file = this.editor.files[normalizePath(`${image}`)];
      const ktx = this.editor.files[normalizePath(`${image}.ktx`)];
      const isNormalMap = image.includes('normal');
      if (file && !ktx) {
        uncompressed.push({ tag: 'image', file: { ...file }, isNormalMap, isRoughnessMetallic: false });
      }
    }

    this.editor.compression = {
      current: 0,
      total: uncompressed.length,
      nodes: uncompressed,
    };

    for await (const _ of window.fs.compressImages(uncompressed)) {
      this.bumpCompression();
    }

    /*
    for await (const _ of window.fs.zipFiles(Array.from(nodes))) {
      this.bumpZip();
    }
    */

    if (!this.isHeightfieldProcessed(terrain)) {
      this.processHeightfield(terrain);
    }

    delete this.editor.compression;
  }

  async processHeightfield(asset: FilesystemNode) {
    if (asset.type !== NodeTypes.TERRAIN) return;

    const assetString = JSON.stringify(asset);
    const resourcesFolder = `${this.editor.project?.directory}/resources/`;
    await window.fs.processHeightfield(resourcesFolder, assetString);
  }

  isModelCompressed(mesh: FilesystemNode): boolean {
    if (!this.editor.project?.directory) return false;
    if (mesh.type !== NodeTypes.MODEL) return false;

    const directory = getDirectory(mesh.path);

    // Model should at least have lod 0
    if (!this.editor.files[normalizePath(`${mesh.path}.0.lod`)]) {
      return false;
    }

    const images = this.editor.modelFiles?.[mesh.path]?.gltf?.images ?? [];

    for (const image of images) {
      const imagePath = `${directory}/${decodeURIComponent(image.uri)}`;
      if (!this.isTextureCompressed(imagePath)) {
        return false;
      }
    }

    return true;
  }

  isFontCompressed(mesh: FilesystemNode): boolean {
    if (!this.editor.project?.directory) return false;
    if (mesh.type !== NodeTypes.FONT) return false;

    const metaPath = normalizePath(`${mesh.path}.meta`);
    const meta = this.editor.files[metaPath];
    return meta !== undefined;
  }

  isSpriteProcessed(path: string) {
    const metaPath = normalizePath(`${path}.meta`);
    const meta = this.editor.files[metaPath];
    return meta !== undefined;
  }

  isTextureCompressed(path: string) {
    const ktxPath = normalizePath(`${path}.ktx`);
    const ktx = this.editor.files[ktxPath];
    return ktx !== undefined;
  }

  async compressModel(mesh: FilesystemNode, forceCompression = false) {
    if (this.editor.compression) return; // compression already in progress
    if (!this.editor.project?.directory) return;
    if (mesh.type !== NodeTypes.MODEL) return;

    const {
      uncompressed,
      uncompressedImages,
      uncompressedModels,
    }: {
      uncompressed: CompressionRequest[];
      uncompressedImages: ImageCompression[];
      uncompressedModels: ModelCompression[];
    } = this.collectCompressionNeedsForMesh(mesh, forceCompression);

    this.editor.compression = {
      current: 0,
      total: uncompressed.length,
      nodes: uncompressed,
    };

    for await (const _ of window.fs.packGltf(uncompressedModels)) {
      this.bumpCompression();
    }

    for await (const _ of window.fs.compressImages(uncompressedImages)) {
      this.bumpCompression();
    }

    delete this.editor.compression;

    const directory = getDirectory(mesh.path) + '/';

    await timeout(100);

    const nodes = Object.values(this.editor.files)
      .filter((file) => file.path.startsWith(directory))
      .filter(
        (file) =>
          file.type !== NodeTypes.DIRECTORY &&
          file.type !== NodeTypes.ZIP &&
          //file.type !== NodeTypes.COMPRESSED &&
          file.type !== NodeTypes.IMAGE &&
          file.type !== NodeTypes.META
      )
      .map((file) => ({ ...file }));

    this.editor.zip = {
      current: 0,
      total: nodes.length,
      nodes,
    };

    for await (const _ of window.fs.zipFiles(Array.from(nodes))) {
      this.bumpZip();
    }

    delete this.editor.zip;
    /*
     */
  }

  private collectCompressionNeedsForMesh(mesh: FilesystemNode, forceCompression: boolean) {
    const uncompressed: CompressionRequest[] = [];
    const uncompressedImages: ImageCompression[] = [];

    const directory = getDirectory(mesh.path);
    const images = this.editor.modelFiles?.[mesh.path]?.gltf?.images ?? [];
    for (const image of images) {
      const uri = decodeURIComponent(image.uri);
      const path = normalizePath(`${directory}/${uri}`);
      const file = this.editor.files[normalizePath(`${directory}/${uri}`)];
      const ktx = this.editor.files[normalizePath(`${directory}/${uri}.ktx`)];
      if (!file) {
        console.error(`referenced image: '${path}' cannot be found. Skipping .`);
        continue;
      }
      const info = this.getMaterialInfo(file.path);

      if (!file || !info) {
        console.error(`The model file references '${uri}' but it cannot be found. Compression will fail.`);
      }

      if (file && (!ktx || forceCompression)) {
        const request = {
          tag: 'image',
          file: { ...file },
          isNormalMap: info.isNormalMap,
          isRoughnessMetallic: info.isRoughnessMetallic,
        };
        uncompressed.push(request as ImageCompression);
        uncompressedImages.push(request as ImageCompression);
      }
    }

    const numLods = 1;
    const uncompressedModels: ModelCompression[] = [];
    for (let lod = 0; lod < numLods; lod++) {
      const lodPath = normalizePath(`${directory}/${mesh.path}.${lod}.lod`);
      const lodFull = normalizePath(`${mesh.full}.${lod}.lod`);
      const file = this.editor.files[lodPath];
      if (!file || forceCompression) {
        const request = { tag: 'model', file: { ...mesh }, lod, lodPath: lodFull };
        uncompressed.push(request as ModelCompression);
        uncompressedModels.push(request as ModelCompression);
      }
    }
    return { uncompressed, uncompressedImages, uncompressedModels };
  }

  async compressFont(asset: FilesystemNode) {
    if (this.editor.compression) return; // compression already in progress
    if (!this.editor.project?.directory) return;
    if (asset.type !== NodeTypes.FONT) return;

    await window.fs.compressFont({ ...asset });
  }

  async processSprite(asset: FilesystemNode) {
    if (asset.type !== NodeTypes.IMAGE || this.editor.compression) return;

    const meta = this.editor.files[normalizePath(`${asset.path}.meta`)];
    if (meta) return;

    await window.fs.processSprite({ ...asset });
  }

  async compressTexture(asset: FilesystemNode, forceCompression = false) {
    if (asset.type !== NodeTypes.IMAGE || this.editor.compression) return;

    const ktx = this.editor.files[normalizePath(`${asset.path}.ktx`)];
    if (ktx && !forceCompression) return;

    const uncompressed: ImageCompression[] = [
      { tag: 'image', file: { ...asset }, isNormalMap: false, isRoughnessMetallic: false },
    ];
    console.info('compressing', uncompressed);

    this.editor.compression = {
      current: 0,
      total: uncompressed.length,
      nodes: uncompressed,
    };

    for await (const _ of window.fs.compressImages(uncompressed)) {
      this.bumpCompression();
    }

    delete this.editor.compression;
  }

  isHeightfieldProcessed(asset: FilesystemNode) {
    const assetAny = asset as any;

    for (const tile of assetAny.tiles) {
      for (const texture of tile) {
        if (texture.type === 'height') {
          const metaPath = normalizePath(`${texture.path}.meta`);
          const meta = this.editor.files[metaPath];
          if (!meta) {
            return false;
          }
        }
      }
    }

    return true;
  }

  get compression(): Compression | null {
    if (!this.editor.compression) return null;
    return this.editor.compression;
  }

  get zip(): Zip | null {
    if (!this.editor.zip) return null;
    return this.editor.zip;
  }

  get compressionNode(): CompressionRequest | null {
    if (!this.editor.compression) return null;
    const index = this.editor.compression.current;
    return this.editor.compression.nodes?.[index];
  }

  bumpCompression() {
    if (!this.editor.compression) return;

    const index = this.editor.compression.current + 1;
    this.editor.compression.current = index;
  }

  bumpZip() {
    if (!this.editor.zip) return;

    const index = this.editor.zip.current + 1;
    this.editor.zip.current = index;
  }

  get zipNode(): FilesystemNode | null {
    if (!this.editor.zip) return null;

    return this.editor.zip.nodes[this.editor.zip.current];
  }

  async loadAnimation(mesh: FilesystemNode) {
    const localFilename = normalizePath(mesh.path);
    const gltf = this.editor.modelFiles?.[mesh.path].gltf;

    if (!gltf || !gltf.animations) return;

    const collection_id = uuid.v4();
    const definitions = [];
    for (const config of gltf.animations) {
      const id = uuid.v4();
      const definition = {
        id,
        collection_id,
        name: config.name,
        loops: true,
      };
      definitions.push(definition);
    }

    const Animation = {
      id: collection_id,
      source: localFilename,
      definitions,
      loops: true,
    };
    this.level.scene.animations[collection_id] = Animation;
    const updateAssets = [{ Animation }];
    sendToLevel({ updateAssets });
  }

  async reloadAnimation(mesh: FilesystemNode) {
    const localFilename = normalizePath(mesh.path);
    const gltf = this.editor.modelFiles?.[mesh.path].gltf;

    if (!gltf || !gltf.animations) return;

    const oldAnimations = this.getMemoryAnimations(mesh.path);

    if (oldAnimations.length === 0) return;

    const mapNameToId: { [name: string]: string } = {};
    const removeAssets = [];
    // @ts-ignore
    const updateAssets = [];

    // old animations
    for (const collection of oldAnimations) {
      const collection_id = collection.id;
      for (const definition of collection.definitions) {
        const id = definition.id;
        const name = definition.name;
        mapNameToId[name] = id;
      }
      removeAssets.push({ Animation: collection });
      delete this.level.scene.animations[collection_id];

      const definitions = [];
      for (const config of gltf.animations) {
        let id = uuid.v4();
        if (mapNameToId[config.name]) {
          id = mapNameToId[config.name];
        }
        const definition = {
          id,
          collection_id,
          name: config.name,
          loops: true,
        };
        definitions.push(definition);
      }

      const Animation = {
        id: collection_id,
        source: localFilename,
        definitions,
        loops: true,
      };
      this.level.scene.animations[collection_id] = Animation;
      updateAssets.push({ Animation });
    }

    sendToLevel({ removeAssets });
    //sendToLevel({ reloadAssets });
    setTimeout(() => {
      // no fucking idea why the types get lost here
      // @ts-ignore
      sendToLevel({ updateAssets });
    }, 1000);
  }

  getTerrainImages(asset: FilesystemNode | TerrainDefinition): string[] {
    // @ts-ignore
    const tiles = asset.tiles
      .map((tile: any) => {
        const result = [];
        // @ts-ignore it will be here. Need to find a way to better type this
        const albedo = tile.find((subtile) => subtile.type === TerrainType.ALBEDO);
        if (albedo) result.push(albedo.path);
        // @ts-ignore it will be here. Need to find a way to better type this
        const normals = tile.find((subtile) => subtile.type === TerrainType.NORMALS);
        if (normals) result.push(normals.path);
        // @ts-ignore it will be here. Need to find a way to better type this
        const grass = tile.find((subtile) => subtile.type === TerrainType.GRASS);
        if (grass) result.push(grass.path);
        return result;
      })
      .flat();

    return tiles;
  }

  async loadTerrain(file: FilesystemNode) {
    return;
    /*
    if (file.type !== NodeTypes.TERRAIN) return;

    const id = uuid.v4();

    // @ts-ignore it will be here. Need to find a way to better type this
    const includeGrass = tile.find((subtile) => subtile.type === TerrainType.GRASS);
    const Terrain = {
      id,
      triangles: 100,
      source: file.name,
      maxX: file.maxX,
      maxY: file.maxY,
      width: file.width,
      height: file.height,
      tileCount: file.tileCount,
      includeGrass: includeGrass.len() > 0,
    };
    this.level.scene.terrains[id] = Terrain;
    sendToLevel({ updateAssets: [{ Terrain }] });
     */
  }

  getHeightfieldImages(asset: FilesystemNode | HeightfieldDefinition): string[] {
    // @ts-ignore
    const tiles = asset.tiles
      .map((tile: any) => {
        const result = [];
        // @ts-ignore it will be here. Need to find a way to better type this
        const height = tile.find((subtile) => subtile.type === TerrainType.HEIGHT);
        if (height) result.push(height.path);
        return result;
      })
      .flat();

    return tiles;
  }

  async loadHeightfield(file: FilesystemNode) {
    const id = uuid.v4();
    // @ts-ignore it will be here. Need to find a way to better type this
    const Heightfield = {
      id,
      name: file.name,
      source: file.name,
      maxX: 1,
      maxZ: 1,
      decimation: 8,
    };
    console.error('this is broken');
    if (!this.level.scene.heightfields) {
      this.level.scene.heightfields = {};
    }
    this.level.scene.heightfields[id] = Heightfield;
    sendToLevel({ updateAssets: [{ Heightfield }] });
  }

  async reloadTrimesh(mesh: FilesystemNode) {
    const asset = this.getMemoryTrimesh(mesh.path);

    if (!asset) return;

    const localFilename = normalizePath(mesh.path);
    const Trimesh = {
      id: asset.id,
      source: localFilename,
    };

    await this.compressModel(mesh, true);

    sendToLevel({ reloadAssets: [{ Trimesh }] });
  }

  async loadTrimesh(mesh: FilesystemNode) {
    const localFilename = normalizePath(mesh.path);
    const id = uuid.v4();
    const Trimesh = {
      id,
      source: localFilename,
    };
    this.level.scene.trimeshes[id] = Trimesh;
    sendToLevel({ updateAssets: [{ Trimesh }] });
  }

  async reloadModel(mesh: FilesystemNode) {
    const asset = this.getMemoryModel(mesh.path);

    if (!asset) return;

    const localFilename = normalizePath(mesh.path);
    const Model = {
      id: asset.id,
      source: localFilename,
      maxInstances: 10,
    };

    await this.compressModel(mesh, true);

    sendToLevel({ reloadAssets: [{ Model }] });
  }

  async loadModel(mesh: FilesystemNode) {
    const localFilename = normalizePath(mesh.path);
    const id = uuid.v4();
    const Model = {
      id,
      source: localFilename,
      maxInstances: 10,
    };
    this.level.scene.models[id] = Model;
    sendToLevel({ updateAssets: [{ Model }] });
  }

  async packModel(mesh: FilesystemNode, lod: number) {
    const directory = getDirectory(mesh.path);
    const lodPath = normalizePath(`${directory}/${mesh.path}.${lod}.lod`);
    window.fs.packGltf([{ tag: 'model', file: mesh, lod, lodPath }]);
  }

  async loadFont(mesh: FilesystemNode) {
    const localFilename = normalizePath(mesh.path);
    const id = uuid.v4();
    const Font = {
      id,
      source: localFilename,
    };
    if (!this.level.scene.fonts) {
      this.level.scene.fonts = {};
    }
    this.level.scene.fonts[id] = Font;
    sendToLevel({ updateAssets: [{ Font }] });
  }

  async loadNavmesh(mesh: FilesystemNode) {
    const localFilename = normalizePath(mesh.path);
    const id = uuid.v4();
    const Navmesh = {
      id,
      name: 'unknown',
      origin: [0, 0, 0] as Vec3,
      scale: [1, 1, 1] as Vec3,
      source: localFilename,
    };
    if (!this.level.scene.navmeshes) {
      this.level.scene.navmeshes = {};
    }
    this.level.scene.navmeshes[id] = Navmesh;
    sendToLevel({ updateAssets: [{ Navmesh }] });
  }

  async loadTexture(texture: FilesystemNode) {
    const localFilename = normalizePath(texture.path);
    const id = uuid.v4();
    const Texture = {
      id,
      source: localFilename,
      isNormalMap: false,
    };
    this.level.scene.textures[id] = Texture;
    sendToLevel({ updateAssets: [{ Texture }] });
  }

  async loadSprite(texture: FilesystemNode) {
    const localFilename = normalizePath(texture.path);
    const id = uuid.v4();
    const Sprite = {
      id,
      source: localFilename,
    };
    if (!this.level.scene.sprites) {
      this.level.scene.sprites = {};
    }
    this.level.scene.sprites[id] = Sprite;
    sendToLevel({ updateAssets: [{ Sprite }] });
  }

  async reloadTexture(asset: FilesystemNode) {
    const texture = this.getMemoryTexture(asset.path);

    if (!texture) return;

    await this.compressTexture(asset, true);

    const localFilename = normalizePath(asset.path);
    const Texture = {
      id: texture.id,
      source: localFilename,
      isNormalMap: false,
    };

    sendToLevel({ reloadAssets: [{ Texture }] });
  }

  async loadAudio(asset: FilesystemNode) {
    if (asset.type !== NodeTypes.AUDIO) {
      return;
    }

    const source = normalizePath(asset.path);
    const id = uuid.v4();
    const Audio = {
      id,
      source,
    };
    this.level.scene.audio[id] = Audio;
    sendToLevel({ updateAssets: [{ Audio }] });
  }

  async reloadAudio(asset: FilesystemNode) {
    const audio = this.getMemoryAudio(asset.path);
    if (!audio) return;

    const localFilename = normalizePath(asset.path);
    const Audio = {
      id: audio.id,
      source: localFilename,
    };

    sendToLevel({ reloadAssets: [{ Audio }] });
  }

  async loadAudioGraph(asset: FilesystemNode) {
    if (asset.type !== NodeTypes.AUDIO_GRAPH) {
      return;
    }

    const source = normalizePath(asset.path);
    const id = uuid.v4();
    const AudioGraph = {
      id,
      source,
    };

    if (!this.level.scene.audio_graphs) this.level.scene.audio_graphs = {};
    this.level.scene.audio_graphs[id] = AudioGraph;
    // sendToLevel({ updateAssets: [{ Audio }] });
  }

  async loadParticle(asset: FilesystemNode) {
    if (asset.type !== NodeTypes.PARTICLE) {
      return;
    }

    const content = await window.fs.readFile(asset.full);
    const data = JSON.parse(content) as Particle;

    const id = data.id;
    const source = normalizePath(asset.path);

    const Particle = {
      id,
      source,
    };

    if (!this.level.scene.particles) this.level.scene.particles = {};
    this.level.scene.particles[id] = Particle;
    // sendToLevel({ updateAssets: [{ Particle }] });
  }

  async reloadAudioGraph(asset: FilesystemNode) {
    const graph = this.getMemoryAudioGraph(asset.path);
    if (!graph) return;

    // const localFilename = normalizePath(asset.path);
    // const AudioGraph = {
    //   id: graph.id,
    //   source: localFilename,
    // };

    // sendToLevel({ reloadAssets: [{ Audio }] });
  }

  getMemoryAssets<K extends keyof Scene>(key: K): ValueOf<Scene[K]>[] {
    const assets = this.level.scene[key];
    return assets ? (Object.values(assets) as ValueOf<Scene[K]>[]) : [];
  }

  getMemoryAsset<K extends keyof Scene>(
    key: K,
    predicate: (item: ValueOf<Scene[K]>) => boolean
  ): ValueOf<Scene[K]> | null {
    const assets = this.getMemoryAssets(key) ?? [];
    const asset = assets.find(predicate);
    if (!asset) return null;
    else return asset;
  }

  isAssetLoadedInMemory<K extends keyof Scene>(key: K, query: (item: ValueOf<Scene[K]>) => boolean): boolean {
    const asset = this.getMemoryAsset(key, query);
    return !!asset;
  }

  getMemoryAnimations(source: string): AnimationCollectionDefinition[] {
    return this.getMemoryAssets('animations').filter((asset) => asset.source === source) ?? null;
  }

  getMemoryTrimesh(source: string): TrimeshDefinition | null {
    return this.getMemoryAssets('trimeshes').find((asset) => asset.source === source) ?? null;
  }

  getMemoryFont(source: string): FontDefinition | null {
    // @ts-ignore ??? not sure what ts doesn't like about this
    return this.getMemoryAssets('fonts').find((model) => model.source === source) ?? null;
  }

  getMemoryNavmesh(source: string): NavmeshDefinition | null {
    // @ts-ignore ??? not sure what ts doesn't like about this
    return this.getMemoryAssets('navmeshes').find((model) => model.source === source) ?? null;
  }

  getMemoryModel(source: string): ModelDefinition | null {
    return this.getMemoryAssets('models').find((model) => model.source === source) ?? null;
  }

  getMemorySprite(source: string): SpriteDefinition | null {
    // @ts-ignore ??? not sure what ts doesn't like about this
    return this.getMemoryAssets('sprites').find((texture) => texture.source === source) ?? null;
  }

  getMemoryTexture(source: string): TextureDefinition | null {
    return this.getMemoryAssets('textures').find((texture) => texture.source === source) ?? null;
  }

  getMemoryAudio(source: string): AudioDefinition | null {
    return this.getMemoryAssets('audio').find((audio) => audio.source === source) ?? null;
  }

  getMemoryAudioGraph(source: string): AudioGraphDefinition | null {
    // @ts-ignore ??? not sure what ts doesn't like about this
    return this.getMemoryAssets('audio_graphs').find((graph) => graph.source === source) ?? null;
  }

  getMemoryParticle(source: string): ParticleDefinition | null {
    // @ts-ignore ??? not sure what ts doesn't like about this
    return this.getMemoryAssets('particles').find((particle) => particle.source === source) ?? null;
  }

  getMemoryTerrain(source: string): TerrainDefinition | null {
    return this.getMemoryAssets('terrains').find((terrain) => terrain.source === source) ?? null;
  }

  getMemoryHeightfield(source: string): HeightfieldDefinition | null {
    const heightfields = this.getMemoryAssets('heightfields') as HeightfieldDefinition[];
    return heightfields.find((heightfield) => heightfield.source === source) ?? null;
  }

  isTerrainLoadedInMemory(source: string): boolean {
    return this.isAssetLoadedInMemory('terrains', (item) => item.source === source);
  }

  isHeightfieldLoadedInMemory(source: string): boolean {
    // @ts-ignore ??? not sure what ts doesn't like about this
    return this.isAssetLoadedInMemory('heightfields', (item) => item.source === source);
  }

  isAnimationLoadedInMemory(source: string): boolean {
    return this.isAssetLoadedInMemory('animations', (item) => item.source === source);
  }

  isTextureLoadedInMemory(source: string): boolean {
    return this.isAssetLoadedInMemory('textures', (item) => item.source === source);
  }

  // TODO: This will get redone once we verify that it actually works and is useful for everyone.
  // We will use a parser so we can then ingest tokens for proper highlighting.
  // https://peggyjs.org/online.html
  updateSearch(search: string) {
    const tokens = search.split(' ');

    const result: SearchParameters = {
      name: '',
      withComponents: [],
      withoutComponents: [],
      context: ['assets', 'outline'],
      assets: [],
    };

    for (const token of tokens) {
      if (token.startsWith('with:')) {
        const right = (token.split(':')[1] || '').toLowerCase().trim();
        if (right.length == 0) continue;
        const values = right.split(',');
        result.withComponents.push(values);
      } else if (token.startsWith('without:')) {
        const right = (token.split(':')[1] || '').toLowerCase().trim();
        if (right.length == 0) continue;
        const values = right.split(',');
        result.withoutComponents.push(values);
      } else if (token.startsWith('context:')) {
        const right = (token.split(':')[1] || '').toLowerCase().trim();
        if (right.length == 0) continue;
        const values = right.split(',');
        result.context = values;
      } else if (token.startsWith('assets:')) {
        const right = (token.split(':')[1] || '').toLowerCase().trim();
        if (right.length == 0) continue;
        const values = right.split(',');
        result.assets = values;
      } else {
        if (result.name.length == 0) {
          result.name = token.toLowerCase();
        } else {
          result.name += ' ' + token.toLowerCase();
        }
      }
    }

    this.editor.search = result;
  }

  setConfig(config: Config) {
    this.level.config = config;
    sendToLevel({ updateConfig: this.level.config });
    sendToVfx({ updateConfig: this.level.config });
  }

  searchedAssets(assets: FilesystemNode[], complex = false): FilesystemNode[] {
    if (!this.editor.project?.directory) return [];

    let filtered = assets;
    if (!this.editor.search.context.includes('assets')) {
      return filtered;
    }

    if (complex) {
      if (this.editor.search.assets.length > 0) {
        filtered = [];
      }

      for (const assetType of this.editor.search.assets) {
        filtered.push(
          ...assets.filter((asset) => asset.type.toLowerCase().includes(assetType.toLowerCase()))
        );
      }
    }

    if (this.editor.search.name === '') {
      return filtered;
    }

    const toSearch = this.editor.search.name.toLowerCase();
    return filtered.filter((asset) => asset.name.toLowerCase().includes(toSearch));
  }

  getTerrainNodes(assets: FilesystemNode[]): FilesystemNode[] {
    const cache: { [name: string]: { path: TerrainTilePath; node: FilesystemNode } } = {};
    const terrainImages = assets
      .filter(
        (asset) =>
          (asset.type === NodeTypes.IMAGE || asset.type === NodeTypes.RAW) &&
          this.getTerrainTilePath(asset.path)
      )
      .map((asset) => {
        const path = this.getTerrainTilePath(asset.path);
        if (path) {
          cache[path.name] = { path, node: asset };
        }
        return path;
      });

    const terrainSubgroups = Object.values(
      groupBy(terrainImages, (asset: FilesystemNode) => {
        const terrain = this.getTerrainTilePath(asset.path);
        if (!terrain) {
          return 'not-terrain';
        }
        return [terrain.name, terrain.x, terrain.y];
      })
    );

    const terrainGroup = Object.entries(
      groupBy(terrainSubgroups, (asset) => {
        // @ts-ignore The type we're getting here is just so beyond wrong...!
        return asset?.[0]?.name ?? 'unknown';
      })
    ).map(([name, tiles]) => {
      //const terrain = cache[name].path;
      return {
        type: NodeTypes.TERRAIN,
        full: 'unknown',
        path: name,
        name,
        isResource: true,
        size: 0,
        extension: 'unknown',
        tiles,
      };
    });

    return terrainGroup;
  }

  getPlannerNodes(): FilesystemNode[] {
    return this.searchedAssets(
      this.planners.map((tree) => {
        return {
          type: NodeTypes.PLANNER,
          full: 'unknown',
          path: tree.id,
          name: tree.name,
          isResource: true,
          size: 0,
          extension: 'unknown',
        };
      })
    );
  }

  getBehaviorTreeNodes(): FilesystemNode[] {
    return this.searchedAssets(
      this.behavior_trees.map((tree) => {
        return {
          type: NodeTypes.BEHAVIOR_TREE,
          full: 'unknown',
          path: tree.id,
          name: tree.name,
          isResource: true,
          size: 0,
          extension: 'unknown',
        };
      })
    );
  }

  getAnimationStateMachineNodes(): FilesystemNode[] {
    return this.searchedAssets(
      this.machines.map((machine) => {
        return {
          type: NodeTypes.ANIMATION_STATE_MACHINE,
          full: 'unknown',
          path: machine.id,
          name: machine.name,
          isResource: true,
          size: 0,
          extension: 'unknown',
        };
      })
    );
  }

  getMaterialNodes(assets: FilesystemNode[]): FilesystemNode[] {
    const materialImages = assets.filter(
      (asset) => asset.type === NodeTypes.IMAGE && this.getMaterialInfo(asset.path)
    );
    const materialGroups = Object.entries(
      groupBy(materialImages, (asset: FilesystemNode) => {
        const material = this.getMaterialInfo(asset.path);
        if (!material) {
          return 'not-material';
        }

        return `${material.model}--${material.name}`;
      })
    ).map(([_, asset]) => {
      const material = this.getMaterialInfo(asset[0].path);
      return {
        type: NodeTypes.MATERIAL,
        material,
        full: 'unknown',
        path: asset[0].path,
        name: material.name,
        isResource: true,
        size: 0,
        extension: 'unknown',
      };
    });

    return materialGroups;
  }

  getPlanner(id?: string): Planner {
    if (!this.level.scene.planners) {
      this.level.scene.planners = {};
    }
    if (!id) {
      return createDefaultPlanner();
    } else {
      const planner = this.level.scene.planners[id];
      return planner ? planner : createDefaultPlanner();
    }
  }

  savePlanner(planner: Planner) {
    if (!this.level.scene.planners) {
      this.level.scene.planners = {};
    }

    const id = planner.id;

    if (isEqual(this.level.scene.planners[id], planner)) {
      return;
    }

    this.level.scene.planners[id] = planner;
    /*
    sendToLevel({
      resetBehavior: filterBehaviorTree(tree),
    });
    */
  }

  getOrCreateAudioGraph(id: string): Draft<AudioGraph> {
    let draft = this.editor.audioGraphs[id];

    if (!draft) {
      const graph = createDefaultAudioGraph();
      graph.id = id;
      draft = new Draft(graph, 'ag');
    }

    return draft;
  }

  updateAudioGraph(graph: Draft<AudioGraph>) {
    this.editor.audioGraphs[graph.resource.id] = graph;
  }

  getBehaviorTree(id?: string): BehaviorTree {
    if (!this.level.scene.behavior_tree) {
      this.level.scene.behavior_tree = {};
    }
    if (!id) {
      return createDefaultBehaviorTree();
    } else {
      const tree = this.level.scene.behavior_tree[id];
      return tree ? tree : createDefaultBehaviorTree();
    }
  }

  saveBehaviorTree(tree: BehaviorTree) {
    if (!this.level.scene.behavior_tree) {
      this.level.scene.behavior_tree = {};
    }

    const id = tree.id;

    if (isEqual(this.level.scene.behavior_tree[id], tree)) {
      return;
    }

    this.level.scene.behavior_tree[id] = tree;
    sendToLevel({
      resetBehavior: filterBehaviorTree(tree),
    });
  }

  getAnimationStateMachine(id?: string): AnimationStateMachine {
    if (!this.level.scene.animation_state) {
      this.level.scene.animation_state = {};
    }
    if (!id) {
      return createDefaultStateMachine();
    } else {
      const machine = this.level.scene.animation_state[id];
      return machine ? machine : createDefaultStateMachine();
    }
  }

  saveAnimationStateMachine(machine: AnimationStateMachine) {
    if (!this.level.scene.animation_state) {
      this.level.scene.animation_state = {};
    }

    const id = machine.id;

    if (machine.nodes.findIndex((node) => node.id == machine.starting_node) == -1) {
      machine.starting_node = machine.nodes[0]?.id;
    }

    if (isEqual(this.level.scene.animation_state[id], machine)) {
      return;
    }

    this.level.scene.animation_state[id] = machine;
    sendToLevel({
      resetMachine: filterStateMachine(machine),
    });
  }

  updateNavmesh(source: string, origin: Vec3, rotation: Vec4, scale: Vec3) {
    const navmesh = this.navmeshes.find((navmesh) => navmesh.source === source);
    if (!navmesh) return;
    navmesh.origin = origin;
    navmesh.rotation = rotation;
    navmesh.scale = scale;
    sendToLevel({
      updateNavmesh: { source, origin, rotation, scale },
    });
  }

  getAssets(nodeTypes: NodeTypes[]): [string, FilesystemNode[]][] {
    if (!this.editor.project?.directory) return [];

    const assetFiles = [];

    for (const asset of this.searchedAssets(Object.values(this.editor.files))) {
      const model = this.getGltf(asset);

      if (model) {
        if (model?.meshes) {
          assetFiles.push({
            ...asset,
            type: NodeTypes.MODEL,
          });
        }

        if (model?.animations) {
          assetFiles.push({
            ...asset,
            type: NodeTypes.ANIMATION,
          });
        }
      } else {
        assetFiles.push(asset);
      }
    }

    // grab only the assets we need
    const assets = assetFiles.filter((asset) => nodeTypes.includes(asset.type) && asset.isResource);

    // transform images into potential materials
    const materialGroups = this.searchedAssets(this.getMaterialNodes(assets), true);
    const terrainGroups = this.searchedAssets(this.getTerrainNodes(assets), true);
    const animationStateMachineGroups = this.searchedAssets(this.getAnimationStateMachineNodes(), true);
    const behaviorTreeGroups = this.searchedAssets(this.getBehaviorTreeNodes(), true);
    const plannerGroups = this.searchedAssets(this.getPlannerNodes(), true);

    let files = assets.filter(
      (file) =>
        file.type !== NodeTypes.TERRAIN &&
        file.type !== NodeTypes.MATERIAL &&
        !this.getMaterialInfo(file.path)
    );

    files = this.searchedAssets(files, true);

    const allAssets = [
      ...Object.values(files),
      ...Object.values(materialGroups),
      ...animationStateMachineGroups,
      ...behaviorTreeGroups,
      ...terrainGroups,
      ...plannerGroups,
    ];

    return Object.entries(
      groupBy(allAssets, (asset: FilesystemNode) => {
        return asset.type;
      })
    );
  }

  async openProject(): Promise<number | null> {
    if (!this.editor.project?.directory) return null;

    const [arcadefile, files] = await window.game.readGame(this.editor.project?.directory);
    this.editor.arcadefile = yaml.parse(arcadefile) as Arcadefile;

    await this.addFiles(files);
    const index = datasets.level.getWithDefault(0);
    this.editor.openLevel = datasets.level.getWithDefault(index);
    return index;
  }

  async openLevel(index: number): Promise<void> {
    if (!this.editor.project?.directory) return;
    if (this.editor.levels.length <= 0) return;

    if (index >= this.editor.levels.length) {
      index = 0;
    }

    this.editor.openLevel = index;
    datasets.level.set(index);

    const filePath = this.editor.levels[this.editor.openLevel].location;
    const levelData = await migrateLevel(JSON.parse(await window.fs.readFile(filePath)));

    const filename = await window.fs.getFilename(filePath);
    levelData.filename = filename;

    this.resetLevel(levelData);
  }

  async openLevelFile(level: FilesystemNode): Promise<void> {
    if (level.type !== NodeTypes.SCENE) return undefined;
    const index = this.editor.levels.findIndex((item) => item.path === level.path);
    if (index < 0) return;
    await this.openLevel(index);
  }

  verifyKeys(data: any, schema: Schema): any {
    if (typeof data === 'object') {
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'symbol') {
          const newDefault = this.getAssetList(value);
          if (!newDefault) continue;
          data[key] = newDefault.map((item) => item.id)[0];
        } else if (schema.tag === 'Struct') {
          const field = schema.fields.find((item) => item.name === key);
          if (!field || !data[key]) continue;
          data[key] = this.verifyKeys(data[key], field.ty);
        } else if (schema.tag === 'Enum') {
          const variant = schema.variants.find((item) => item.name === key);
          if (!variant || !data[key]) continue;
          // This is an object. Which means, that the enum has already been extended
          data[key] = this.getDefaultValue(variant, true);
        }
      }
    } else if (typeof data === 'string' && schema.tag === 'Enum') {
      const variant = schema.variants.find((inner) => inner.name === data);
      if (!variant) return data;
      const shouldSkipTag = variant.tag === 'Unit';
      //data[key] = { [key]: this.verifyKeys(data[key], variant) };
      data = this.getDefaultValue(variant, shouldSkipTag);
    }

    return data;
  }

  getDefaultValue(schema: Schema, skipKey = false): any {
    try {
      // NOTE: Not safe at all
      let evaluated = eval(`(() => (${schema.default_values}))()`);

      if (schema.tag === 'Enum' && skipKey && schema.variants.length > 0) {
        const newDefault = schema.variants.find((item) => item.name === evaluated);
        if (newDefault) evaluated = this.verifyKeys(this.getDefaultValue(newDefault), schema);
      } else if (schema.tag === 'Struct' && skipKey) {
        evaluated = this.verifyKeys(evaluated, schema);
      } else if (schema.tag === 'Enum' && !skipKey && schema.variants.length > 0) {
        const newDefault = schema.variants.find((item) => item.name === evaluated);
        if (!newDefault) evaluated = { [schema.name]: evaluated };
        // The extra key is already added on the outer layer. No need to do it twice.
        // At the same time, in order for verifyKeys to work correctly, we need to run it only on the
        // inner body of the data
        else {
          const inner = this.getDefaultValue(newDefault);
          evaluated = { [schema.name]: this.verifyKeys(inner, schema) };
        }
      } else if (schema.tag === 'Struct' && !skipKey) {
        evaluated = { [schema.name]: this.verifyKeys(evaluated, schema) };
      }

      return evaluated;
    } catch (e) {
      console.error('Error:', schema.default_values);
      throw e;
    }
  }

  pushNewTab(type: string, label: string) {
    const id = uuid.v4();
    this.editor.contentBrowser.panels.push({
      id,
      label,
      type,
      protected: false,
    });
    this.editor.contentBrowser.selected = id;
  }

  pushNewCommentsTab(label: string) {
    const id = uuid.v4();
    this.editor.inspector.panels.push({
      id,
      label,
      type: COMMENTS,
      protected: false,
    });
    this.editor.inspector.selected = id;
  }

  pushNewAssetConfigTab(label: string, asset: FilesystemNode) {
    const id = uuid.v4();
    const newPanel = {
      id,
      label,
      type: ASSET_CONFIG,
      protected: false,
      data: { asset },
    };

    this.editor.inspector.selected = id;
    const panelIndex = this.editor.inspector.panels.findIndex((panel) => panel.type === ASSET_CONFIG);
    if (panelIndex >= 0) {
      this.editor.inspector.panels[panelIndex] = newPanel;
    } else {
      this.editor.inspector.panels.unshift(newPanel);
    }
  }

  pushNewEntityTab(label: string) {
    const id = uuid.v4();
    const newPanel = {
      id,
      label,
      type: ENTITY,
      protected: true,
    };
    const panelIndex = this.editor.inspector.panels.findIndex((panel) => panel.type === ENTITY);
    if (panelIndex >= 0) {
      this.editor.inspector.panels[panelIndex] = newPanel;
    } else {
      this.editor.inspector.panels.push(newPanel);
    }

    this.editor.inspector.selected = id;
  }

  pushNewThreadTab(label: string, threadId: string) {
    const id = uuid.v4();
    const panel = this.editor.inspector.panels.find((panel) => panel.data?.entityId === threadId);
    if (panel) return;

    this.editor.inspector.panels.push({
      id,
      label,
      type: THREAD,
      protected: false,
      data: { threadId },
    });
    this.editor.inspector.selected = id;
  }

  pushNewAnimationTab(label: string, animationId: string) {
    const id = uuid.v4();
    this.editor.contentBrowser.panels.push({
      id,
      label,
      type: ANIMATION_STATE,
      protected: false,
      data: { animationId },
    });
    this.editor.contentBrowser.selected = id;
  }

  pushNewBehaviorTab(label: string, behaviorId: string) {
    const id = uuid.v4();
    this.editor.contentBrowser.panels.push({
      id,
      label,
      type: BEHAVIOR_TREE,
      protected: false,
      data: { behaviorId },
    });
    this.editor.contentBrowser.selected = id;
  }

  pushNewPlannerTab(label: string, plannerId: string) {
    const id = uuid.v4();
    this.editor.contentBrowser.panels.push({
      id,
      label,
      type: PLANNER,
      protected: false,
      data: { plannerId },
    });
    this.editor.contentBrowser.selected = id;
  }

  pushNewAudioGraphTab(label: string, graphId: string) {
    const id = uuid.v4();
    this.editor.contentBrowser.panels.push({
      id,
      label,
      type: AUDIO_GRAPH,
      protected: false,
      data: { graphId },
    });
  }

  selectOutlineTab(id: string) {
    this.editor.outline.selected = id;
  }

  selectInspectorTab(id: string) {
    this.editor.inspector.selected = id;
  }

  deleteInspectorTab(id: string) {
    const { selected, panels } = this.editor.inspector;

    // if we try to delete the selected entity, switch to the previous tab
    if (selected === id) {
      const index = panels.findIndex((item) => item.id === id);
      this.editor.inspector.selected = panels[index - 1].id;
    }
    const filtered = [...panels];
    this.editor.inspector.panels = filtered.filter((item) => item.id !== id);
  }

  selectContentTab(id: string) {
    this.editor.contentBrowser.selected = id;
  }

  deleteContentTab(id: string) {
    const { selected, panels } = this.editor.contentBrowser;

    // if we try to delete the selected entity, switch to the previous tab
    if (selected === id) {
      const index = panels.findIndex((item) => item.id === id);
      this.editor.contentBrowser.selected = panels[index - 1].id;
    }
    const filtered = [...panels];
    this.editor.contentBrowser.panels = filtered.filter((item) => item.id !== id);
  }

  getAssetListFromIdentifier(identifier: string): AssetInfo[] | null {
    const evaluated = eval(`(() => (${identifier}))()`);
    return this.getAssetList(evaluated);
  }

  // NOTE: This can probably be fully automated, BUT I'll do that later
  // It would require significant refactor of our asset system, which is
  // incoming
  getAssetList(identifier: symbol): AssetInfo[] | null {
    if (identifier === Symbol.for('ModelId')) {
      return this.models.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('SocketId')) {
      const machines = this.machines.map((machine) => {
        const sockets = machine.sockets ?? [];
        return sockets.map((socket) => ({
          id: socket.id,
          name: socket.name,
        }));
      });
      return machines.flat();
    } else if (identifier === Symbol.for('MeshId')) {
      return this.models.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('PrefabProviderId')) {
      return Object.values(this.prefabs)
        .filter((item) => item.id !== this.editor.selectedEntity)
        .filter((item) => getComponent('PrefabProviderComponent', item))
        .map((item) => ({
          id: item.id,
          name: item.tag.name,
        }));
    } else if (identifier === Symbol.for('PrefabId')) {
      return Object.values(this.prefabs)
        .filter((item) => item.id !== this.editor.selectedEntity)
        .map((item) => ({
          id: item.id,
          name: item.tag.name,
        }));
    } else if (identifier === Symbol.for('HdrId')) {
      return this.hdrs.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('BackgroundId')) {
      return this.backgrounds.map((item) => ({
        id: item.id,
        name: item.id,
      }));
    } else if (identifier === Symbol.for('ParticleId')) {
      return this.particles.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('TrimeshId')) {
      return this.trimeshes.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('HeightfieldId')) {
      return this.heightfields.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('TerrainId')) {
      return this.terrains.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('BehaviorTreeId')) {
      return this.behavior_trees.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    } else if (identifier === Symbol.for('PlannerId')) {
      return this.planners.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    } else if (identifier === Symbol.for('FontId')) {
      return this.fonts.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('NavmeshId')) {
      return this.navmeshes.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    } else if (identifier === Symbol.for('SpriteId')) {
      return this.sprites.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('TextureId')) {
      return this.textures.map((item) => ({
        id: item.id,
        name: item.source,
      }));
    } else if (identifier === Symbol.for('AnimationId')) {
      return this.animations.map(([collection, animation]) => {
        const path = normalizePath(collection.source).split('/');
        return {
          id: animation.id,
          data: collection.id,
          name: `${path[path.length - 1]} - ${animation.name}`,
        };
      });
    } else if (identifier === Symbol.for('AnimationStateMachineId')) {
      return this.machines.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    } else if (identifier === Symbol.for('AudioId')) {
      return this.audioTracks.map((item) => {
        const path = normalizePath(item.source).split('/');
        return {
          id: item.id,
          name: `${path[path.length - 1]}`,
        };
      });
    } else {
      return null;
    }
  }

  canComponentBeCreated(schema: Schema): Result<null, string[]> {
    const data = eval(`(() => (${schema.default_values}))()`);
    if (typeof data === 'symbol' && schema.tag === 'Reference') {
      const canCreate = this.canAssetList(data);
      if (canCreate) {
        return ok(null);
      } else {
        return err([`cannot find asset (${schema.reference})`]);
      }
    } else if (typeof data === 'object' && schema.tag === 'Struct') {
      const canCreate = schema.fields
        .map((innerField) => this.canComponentBeCreated(innerField.ty))
        .filter((item) => item.status === 'err');

      // @ts-ignore I know this invariant is correct
      if (canCreate.length > 0) return err(canCreate.map((item) => item.data));

      return ok(null);
    } else if (typeof data === 'object' && schema.tag === 'Enum') {
      const canCreate = schema.variants
        .map((variant) => this.canComponentBeCreated(variant))
        .filter((item) => item.status === 'err');

      // @ts-ignore I know this invariant is correct
      if (canCreate.length > 0) return err(canCreate.map((item) => item.data));

      return ok(null);
    } else {
      return ok(null);
    }
  }

  createEntitiesUpdate = (prefabs: Prefab[]): Message => {
    const update: Message = {
      updateEntities: prefabs.map((entity) => {
        return this.filterComponents({
          id: entity.id,
          tag: entity.tag,
          transform: entity.transform,
          components: entity.components,
        });
      }),
    };

    return update;
  };

  // createDuplicateEntity = (entity: Prefab): Message => {
  //   const duplicate: Message = {
  //     duplicateEntity: entity.id,
  //   };

  //   return duplicate;
  // };

  canAssetList(identifier: symbol): boolean {
    const list = this.getAssetList(identifier);
    if (!list) return false;
    return list.length > 0;
  }

  receiveFromRenderer(patch: Message, id: string) {
    if (typeof patch !== 'object') return;

    if ('highlightEntity' in patch) {
      this.highlightEntity(patch.highlightEntity, false);
    } else if ('unhighlightEntity' in patch) {
      this.unhighlightEntity(patch.unhighlightEntity, false);
    } else if ('updateEntity' in patch) {
      this.updateEntity(patch.updateEntity, false);
    } else if ('selectEntity' in patch) {
      if (patch.selectEntity) {
        const entity = this.level.scene.prefabs[patch.selectEntity];
        this.selectEntity(entity);
      } else {
        this.selectEntity(null);
      }
    } else if ('newEntity' in patch) {
      const entity = patch.newEntity.prefab;
      this.level.scene.prefabs[entity.id] = entity;
      this.selectEntity(entity);
    } else if ('duplicateEntity' in patch) {
      const prefabId = patch.duplicateEntity;
      const prefab = this.level.scene.prefabs[prefabId];
      const duplicate = this.duplicateEntity(prefab);
      this.selectEntity(duplicate);
    }
  }
}

export const getComponent = (key: string, prefab: Prefab): any | undefined => {
  return prefab.components.find((component) => {
    if (!component) return false;
    return key in component;
  });
};

type AnimationConfig = [AnimationCollectionDefinition, AnimationDefinition];

type LevelLocation = {
  id: string;
  name: string;
  path: string;
  location: string;
};

type Project = {
  id: string;
  name: string;
  directory: string;
};

//type CompressionRequest = [FilesystemNode, boolean, boolean];

type Compression = {
  current: number;
  total: number;
  nodes: CompressionRequest[];
};

type Zip = {
  current: number;
  total: number;
  nodes: FilesystemNode[];
};

export enum UpdateState {
  UPTODATE,
  DOWNLOADING,
  UPDATE_AVAILABLE,
}

export type Game = {
  id: string;
  name: string;
  shortname: string;
};

export type Parentship = {
  prefab: Prefab;
  children: Parentship[];
};

export type Ok<S> = { status: 'ok'; data: S };
export type Err<E> = { status: 'err'; data: E };

export type Result<S, E> = Ok<S> | Err<E>;

function ok<S>(data: S): Ok<S> {
  return { status: 'ok', data };
}

function err<E>(data: E): Err<E> {
  return { status: 'err', data };
}

export type AssetInfo = {
  id: string;
  name: string;
  data?: unknown;
};

export type Editor = {
  project?: Project;
  arcadefile?: Arcadefile;
  version?: {
    editor: string;
  };
  lockfiles: {
    server?: Lockfile;
    client?: Lockfile;
  };
  openLevel?: number;
  levels: LevelLocation[];
  search: SearchParameters;
  docs?: Docs;
  selectedAsset: FilesystemNode | null;
  selectedEntity: string | null;
  highlightedEntities: string[];
  previouslyOpenedGames: string[];
  fileContent: { [filepath: string]: Content };
  textures: Textures;
  modelFiles: ModelFiles;
  materialTextures: MaterialTextures;
  files: Files;
  compression?: Compression;
  zip?: Zip;
  updateState: UpdateState;
  contentBrowser: Tabs;
  inspector: Tabs;
  outline: Tabs;
  playSession?: string;
  vfx?: VfxEditor;
  behavior: {
    selectedNode: BehaviorNodeId | null;
  };
  animationState: {
    selectedNode: AnimationNodeId | null;
    selectedEdge: AnimationEdgeId | null;
  };
  playing: string | null;
  cli: { [id: string]: Cli };
  audioPlayers: { [id: string]: AudioPlayer };
  audioGraphs: { [id: string]: Draft<AudioGraph> };
};

export type AudioPlayer = {
  id: string;
  playing: boolean;
  looping: boolean;
  paused: boolean;
};

export class Draft<T> {
  source: string | null;
  fullPath: string | null;
  dirty: boolean;
  extension: string;

  private _resource: T;

  constructor(resource: T, extension: string, source?: string, fullPath?: string) {
    this._resource = resource;
    this.extension = extension;
    this.source = source ?? null;
    this.fullPath = fullPath ?? null;
    this.dirty = !source;
  }

  public get resource() {
    return this._resource;
  }

  public set resource(resource: T) {
    this.dirty = true;
    this._resource = resource;
  }

  async save() {
    const content = JSON.stringify(this._resource);
    if (!this.source || !this.fullPath) {
      const result = await window.fs.saveFileAs(content, this.extension);
      if (result.filePath) {
        this.source = result.filePath;
        this.fullPath = result.filePath;
      } else if (result.error) {
        console.error(result.error);
        return;
      } else if (result.canceled) {
        return;
      }
    } else {
      await window.fs.saveFile(this.fullPath, content);
    }
    this.dirty = false;
  }
}

export type Cli = {
  id: string;
  content: string[];
};

export type VfxEditor = {
  item: VfxParticle;
  filename?: string;
  selectedAction?: string;
};

export type Tabs = {
  selected: string | null;
  panels: Panel[];
};

export type Panel =
  | {
      type: typeof PLANNER;
      id: string;
      label: string;
      protected: boolean;
      data: {
        plannerId: string;
      };
    }
  | {
      type: typeof BEHAVIOR_TREE;
      id: string;
      label: string;
      protected: boolean;
      data: {
        behaviorId: string;
      };
    }
  | {
      type: typeof ASSET_CONFIG;
      id: string;
      label: string;
      protected: boolean;
      data: {
        asset: FilesystemNode;
      };
    }
  | {
      type: typeof ANIMATION_STATE;
      id: string;
      label: string;
      protected: boolean;
      data: {
        animationId: string;
      };
    }
  | {
      type: string;
      id: string;
      label: string;
      protected: boolean;
      data?: any;
    }
  | {
      type: typeof BEHAVIOR_TREE;
      id: string;
      label: string;
      protected: boolean;
      data: {
        behaviorId: string;
      };
    }
  | {
      type: typeof ANIMATION_STATE;
      id: string;
      label: string;
      protected: boolean;
      data: {
        animationId: string;
      };
    }
  | {
      type: typeof AUDIO_GRAPH;
      id: string;
      label: string;
      protected: boolean;
      data: {
        graphId: string;
      };
    }
  | {
      type: string;
      id: string;
      label: string;
      protected: boolean;
      data?: any;
    };

export type Docs = {
  subject: string;
  content: string;
};

export type MaterialInfo = {
  name: string;
  model: string;
  isNormalMap: boolean;
  isRoughnessMetallic: boolean;
};

export type Textures = {
  [filepath: string]: TextureDefinition;
};

export type MaterialTextures = {
  [filepath: string]: MaterialInfo;
};

export type ModelFiles = {
  [filepath: string]: ModelFile;
};

export type Files = {
  [filepath: string]: FilesystemNode;
};

export type Prefab = {
  id: IdComponent;
  tag: TagComponent;
  transform: TransformComponent;
  components: any[];
};

const createDefaultEntity = (id: string, name: string, position: Vec3): Prefab => ({
  id,
  tag: { name },
  transform: {
    transform: {
      translation: position,
      rotation: [0.0, 0.0, 0.0],
      scale: [1.0, 1.0, 1.0],
    },
  },
  components: [],
});

type Content = {
  data: string;
  dirty: boolean;
};

export type PrefabComponent = {
  id: string;
};

export type ParentComponent = {
  parent_id: string;
};

export type ModelComponent = {
  id: string;
  offset?: Vec3;
  cast_shadows?: boolean;
};

export type TerrainComponent = {
  id: string;
  height: number;
  terrain_length: number;
};

export type InputComponent = {
  yaw: number;
  pitch: number;
  update: Vec3;
  front: Vec3;
  walk: Vec3;
  sensitivity: number;
  speed: Kph;
};

export type DirectionalLightComponent = {
  radiance: Vec3;
  //direction: Vec3;
  azimuth: Radians;
  inclination: Radians;
  intensity: number;
  should_cast_shadows: boolean;
  are_shadows_soft: boolean;
};

export type PointLightComponent = {
  radiance: Vec3;
  intensity: number;
  falloff: number;
  radius: number;
  should_cast_shadows: boolean;
  are_shadows_soft: boolean;
};

export type SpotLightComponent = {
  radiance: Vec3;
  intensity: number;
  cone_length: number;
  should_cast_shadows: boolean;
  are_shadows_soft: boolean;
  inner_angle: Radians;
  outer_angle: Radians;
};

export type LightComponent = SpotCase | PointCase | DirectionalCase;

export type TagComponent = {
  name: string;
};

export type IdComponent = string;

export type CameraComponent =
  | {
      orthographic: {
        id: string;
        left: number;
        right: number;
        bottom: number;
        top: number;
        znear: number;
        zfar: number;
        primary: boolean;
        fixed_aspect_ratio: boolean;
      };
    }
  | {
      perspective: {
        id: string;
        fovy: Radians;
        znear: number;
        zfar: number;
        primary: boolean;
        fixed_aspect_ratio: boolean;
      };
    };

export enum DynamicType {
  Dynamic = 'dynamic',
  Kinematic = 'kinematic',
  Position = 'position',
  Static = 'static',
  CharacterController = 'character_controller',
}

export enum ColliderType {
  CapsuleY = 'capsuleY',
  CapsuleX = 'capsuleX',
  CapsuleZ = 'capsuleZ',
  Cylinder = 'cylinder',
  Cube = 'cube',
  Sphere = 'sphere',
  Trimesh = 'trimesh',
}

export type ColliderTypes =
  | { [ColliderType.CapsuleY]: { half_height: number; radius: number } }
  | { [ColliderType.CapsuleX]: { half_height: number; radius: number } }
  | { [ColliderType.CapsuleZ]: { half_height: number; radius: number } }
  | { [ColliderType.Cube]: { width: number; height: number; length: number } }
  | { [ColliderType.Sphere]: { radius: number } }
  | { [ColliderType.Trimesh]: { id: string } };

export enum JointType {
  Fixed = 'fixed',
  Spherical = 'spherical',
  Revolute = 'revolute',
}

export type JointConfig =
  | { [JointType.Fixed]: { local_anchor: Vec3 } }
  | { [JointType.Spherical]: { local_anchor: Vec3 } }
  | {
      [JointType.Revolute]: {
        axis: Vec3;
        local_anchor: Vec3;
        // motor: Motor | null;
      };
    };

export type PhysicsJoint = {
  id: string;
  offset: Vec3;
  body: PhysicsBody;
  name: string;
  joints: PhysicsJoint[];
  config: JointConfig | null;
};

export type PhysicsBody = {
  id: string;
  dynamic_type: DynamicType;
  collider_type: ColliderTypes;
  density: number;
  friction: number;
  restitution: number;
  ccd: boolean;
  allow_translation: [boolean, boolean, boolean];
  allow_rotation: [boolean, boolean, boolean];
};

export type PhysicsComponent = {
  id: string;
  debug: boolean;
  joint: PhysicsJoint;
  delta_translation: Vec3;
  delta_rotation: Vec3;
};

export enum AnimationState {
  Paused = 'paused',
  Playing = 'playing',
}

export type Animation = {
  id: string;
  rate: number;
  weight: number;
  current: number;
  state: AnimationState;
};

export type TransformComponent = {
  transform: {
    translation: Vec3;
    rotation: Vec3;
    scale: Vec3;
  };
};

export type DynamicSkyComponent = {
  id: string;
  intensity: number;
  turbidity: number;
  azimuth: Radians;
  inclination: Radians;
};

export type ImageSkyComponent = {
  id: string;
  intensity: number;
  lod: number;
};

export type ParticleComponent = {
  id: string;
  assetId: string;
};

export type CreateEntityUpdate = {
  id: string;
  tag: string;
  transform: TransformComponent;
  components: any[];
};

export type DynamicCase = { dynamic: DynamicSkyComponent };

export type ImageCase = { image: ImageSkyComponent };

export type SkyComponent = DynamicCase | ImageCase;

export type SpotCase = { spot: SpotLightComponent };
export type PointCase = { point: PointLightComponent };
export type DirectionalCase = { directional: DirectionalLightComponent };

export type DynamicBackground = {
  id: string;
};

export enum TerrainType {
  ALBEDO = 'albedo',
  HEIGHT = 'height',
  NORMALS = 'normals',
  GRASS = 'grass',
}

export type TerrainTilePath = {
  path: string;
  name: string;
  type: TerrainType;
  x: number;
  y: number;
};

export type ModelFile = {
  path: string;
  full: string;
  gltf?: Gltf;
  lods: Gltf[];
};
export type Submesh = {
  id: string;
  name: string;
};

export enum ParticleType {
  Point = 'point',
  Quad = 'quad',
  Model = 'model',
}

export type ParticleTypes =
  | { type: ParticleType.Point; point_size: number }
  | { type: ParticleType.Quad; texture_id: string }
  | { type: ParticleType.Model; modle_id: string };

export type ModelScene = {
  [key: string]: ModelDefinition;
};

export type Scene = {
  prefabs: {
    [key: string]: Prefab;
  };
  dynamic_backgrounds: {
    [key: string]: DynamicBackground;
  };
  hdrs: {
    [key: string]: HdrDefinition;
  };
  trimeshes: {
    [key: string]: TrimeshDefinition;
  };
  animations: {
    [key: string]: AnimationCollectionDefinition;
  };
  particles: {
    [key: string]: ParticleDefinition;
  };
  terrains: {
    [key: string]: TerrainDefinition;
  };
  heightfields?: {
    [key: string]: HeightfieldDefinition;
  };
  models: {
    [key: string]: ModelDefinition;
  };
  fonts?: {
    [key: string]: FontDefinition;
  };
  navmeshes?: {
    [key: string]: NavmeshDefinition;
  };
  sprites?: {
    [key: string]: SpriteDefinition;
  };
  textures: {
    [key: string]: TextureDefinition;
  };
  audio: {
    [key: string]: AudioDefinition;
  };
  animation_state?: {
    [key: string]: AnimationStateMachine;
  };
  behavior_tree?: {
    [key: string]: BehaviorTree;
  };
  planners?: {
    [key: string]: Planner;
  };
  audio_graphs?: {
    [key: string]: AudioGraphDefinition;
  };
};

export type SearchParameters = {
  name: string;
  withComponents: string[][];
  withoutComponents: string[][];
  context: string[];
  assets: string[];
};

export const createAnimationNode = (id: Uuid, position: Position, animation_id: [Uuid, Uuid] | null) => {
  return {
    id,
    position,
    animation_id,
    transitions: [],
  };
};

export const createDefaultPlanner = (): Planner => {
  return {
    id: uuid.v4(),
    name: 'Unknown',
    goals: [],
    actions: [],
    sensors: [],
  };
};

export const createDefaultAudioGraphDefinition = (): AudioGraphDefinition => {
  return {
    id: uuid.v4(),
    source: '',
  };
};

export const createDefaultAudioGraph = (): AudioGraph => {
  return {
    id: uuid.v4(),
    name: 'New Audio Graph',
    nodes: [],
    inputs: [],
    params: [],
  };
};

export const createDefaultBehaviorTree = () => {
  return {
    id: uuid.v4(),
    name: 'Unknown',
    floating_nodes: [],
    root: {
      id: uuid.v4(),
      position: { x: 0, y: 0 },
      node_type: {
        Root: { child: null },
      },
    },
  };
};

export const createDefaultStateMachine = () => {
  const starting_node = uuid.v4();
  return {
    id: uuid.v4(),
    name: 'Unknown',
    rate: 1.0,
    starting_node,
    sockets: [],
    target_skeleton: null,
    nodes: [createAnimationNode(starting_node, { x: 0, y: 0 }, null)],
    edges: [],
  };
};

export const createDefaultParticleSystem = (): ParticleDefinition => ({
  id: uuid.v4(),
  source: '',
});

export const createEntityUpdate = ({ id, tag, transform, components }: CreateEntityUpdate): Message => {
  const update: Message = {
    updateEntity: {
      id,
      tag: {
        name: tag,
      },
      transform,
      components,
    },
  };

  return update;
};

export const createUpdateConfig = (config: Config): Message => ({
  updateConfig: config,
});

export const createTerrainAllocation = (
  id: string,
  source: string,
  name: string,
  triangles: number,
  maxX: number,
  maxZ: number,
  width: number,
  height: number,
  tileCount: number,
  materials: any[],
  includeGrass: boolean
): Message => ({
  updateAssets: [
    {
      Terrain: {
        id,
        source,
        name,
        triangles,
        maxX,
        maxZ,
        width,
        height,
        tileCount,
        materials,
        includeGrass,
      },
    },
  ],
});

export const createModelAllocation = (id: string, maxInstances: number, source: string): Message => ({
  updateAssets: [
    {
      Model: {
        id,
        maxInstances,
        source,
      },
    },
  ],
});

export const createParticleAllocation = (particle: ParticleDefinition): Message => ({
  updateAssets: [{ Particle: particle }],
});

export const createAnimationAllocation = (animations: AnimationCollectionDefinition[]): Message => ({
  updateAssets: animations.map((Animation) => ({
    Animation,
  })),
});

const filterBehaviorTree = (tree: BehaviorTree): BehaviorTree => {
  return {
    ...tree,
    root: {
      ...tree.root,
      node_type: {
        Root: {
          ...tree.root.node_type.Root,
          child: null,
        },
      },
    },
  };
};

const updateBehaviorTree = (tree: BehaviorTree): AssetPack => ({
  BehaviorTree: filterBehaviorTree(tree),
});

const filterPlanner = (planner: Planner): Planner => {
  return {
    ...planner,
    goals: [],
    actions: [],
    sensors: [],
  };
};

const updatePlanner = (planner: Planner): AssetPack => ({
  Planner: filterPlanner(planner),
});

const filterStateMachine = (machine: AnimationStateMachine): AnimationStateMachine => {
  const nodes = machine.nodes.map((item): AnimationNode => {
    const node = { ...item, transitions: [] };
    return node;
  });
  const edges = machine.edges.map((item): AnimationEdge => {
    const edge = { ...item, transition: null };
    return edge;
  });
  return { ...machine, edges, nodes };
};

const updateAnimationStateMachine = (animation: AnimationStateMachine): AssetPack => ({
  AnimationStateMachine: filterStateMachine(animation),
});

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const createUpdateVfx = (scene: Scene): Message => ({
  updateAssets: [
    ...Object.values(scene.particles ?? {}).map(
      (Particle): AssetPack => ({
        Particle,
      })
    ),
    ...Object.values(scene.textures ?? {}).map(
      (Texture): AssetPack => ({
        Texture,
      })
    ),
  ],
});

export const createUpdateAssets = (scene: Scene): Message => ({
  updateAssets: [
    ...Object.values(scene.models ?? {}).map(
      (model): AssetPack => ({
        Model: {
          id: model.id,
          maxInstances: model.maxInstances,
          source: model.source,
        },
      })
    ),
    ...Object.values(scene.particles ?? {}).map(
      (Particle): AssetPack => ({
        Particle,
      })
    ),
    ...Object.values(scene.trimeshes ?? {}).map(
      (Trimesh): AssetPack => ({
        Trimesh,
      })
    ),
    ...Object.values(scene.terrains ?? {}).map(
      (Terrain): AssetPack => ({
        Terrain,
      })
    ),
    ...Object.values(scene.heightfields ?? {}).map(
      (Heightfield): AssetPack => ({
        Heightfield,
      })
    ),
    ...Object.values(scene.animations ?? {}).map(
      (Animation): AssetPack => ({
        Animation,
      })
    ),
    ...Object.values(scene.hdrs ?? {}).map(
      (HdrDefinition): AssetPack => ({
        HdrDefinition,
      })
    ),
    ...Object.values(scene.dynamic_backgrounds ?? {}).map(
      (DynamicBackground): AssetPack => ({
        DynamicBackground,
      })
    ),
    ...Object.values(scene.fonts ?? {}).map(
      (Font): AssetPack => ({
        Font,
      })
    ),
    ...Object.values(scene.navmeshes ?? {}).map(
      (Navmesh): AssetPack => ({
        Navmesh,
      })
    ),
    ...Object.values(scene.sprites ?? {}).map(
      (Sprite): AssetPack => ({
        Sprite,
      })
    ),
    ...Object.values(scene.textures ?? {}).map(
      (Texture): AssetPack => ({
        Texture,
      })
    ),
    ...Object.values(scene.audio ?? {}).map(
      (Audio): AssetPack => ({
        Audio,
      })
    ),
    ...Object.values(scene.animation_state ?? {}).map(
      (AnimationStateMachine): AssetPack => updateAnimationStateMachine(AnimationStateMachine)
    ),
    ...Object.values(scene.behavior_tree ?? {}).map(
      (BehaviorTree): AssetPack => updateBehaviorTree(BehaviorTree)
    ),
    ...Object.values(scene.planners ?? {}).map((Planner): AssetPack => updatePlanner(Planner)),
  ],
});

export const defaultEditor: Editor = {
  project: datasets.project.get() ?? undefined,
  openLevel: datasets.level.getWithDefault(0),
  lockfiles: {
    server: undefined,
    client: undefined,
  },
  levels: [],
  search: {
    name: '',
    withComponents: [],
    withoutComponents: [],
    context: [],
    assets: [],
  },
  selectedAsset: null,
  selectedEntity: null,
  highlightedEntities: [],
  previouslyOpenedGames: [],
  files: {},
  fileContent: {},
  textures: {},
  modelFiles: {},
  playing: null,
  materialTextures: {},
  updateState: UpdateState.UPTODATE,
  behavior: {
    selectedNode: null,
  },
  animationState: {
    selectedNode: null,
    selectedEdge: null,
  },
  cli: {},
  vfx: undefined,
  audioPlayers: {},
  audioGraphs: {},
  outline: (() => {
    const id = uuid.v4();
    return {
      selected: id,
      panels: [
        {
          id,
          label: 'Entities',
          type: ENTITIES,
          protected: true,
        },
        {
          id: uuid.v4(),
          label: 'Play Sessions',
          type: PLAYSESSIONS,
          protected: true,
        },
      ],
    };
  })(),
  inspector: (() => {
    const id = uuid.v4();
    return {
      selected: id,
      panels: [
        {
          id,
          label: 'Entity',
          type: ENTITY,
          protected: true,
        },
        {
          id: uuid.v4(),
          label: 'Config',
          type: CONFIG,
          protected: true,
        },
        {
          id: uuid.v4(),
          label: 'Comments',
          type: COMMENTS,
          protected: true,
        },
      ],
    };
  })(),
  contentBrowser: (() => {
    const id = uuid.v4();
    return {
      selected: id,
      panels: [
        {
          id,
          label: 'Assets',
          type: ASSETS,
          protected: true,
        },
      ],
    };
  })(),
};

export const defaultLevel: Level = {
  version: LVL_VERSION,
  filename: 'default.lvl',
  config: {
    id: uuid.v4(),
    name: 'Scene name',
    bloom: {
      threshold: 1.0,
      knee: 1.0,
      enabled: true,
      hdr: true,
    },
    fxaa: {
      enabled: true,
      span_max: 8.0,
      reduce_min: 128.0,
      reduce_mul: 8.0,
    },
    dof: {
      focus_point: 0.5,
      focus_scale: 0.8,
      radius_scale: 1.0,
      enabled: true,
    },
    ssao: {
      enabled: true,
      intensity: 1.0,
      radius: 4.0,
      bias: 0.03,
    },
    ambient: {
      intensity: 0.35,
      fog_config: 'None',
    },
    physics: {
      color: [1.0, 0.0, 0.0],
    },
    shadows: {
      num_cascade_layers: 5,
      max_distance: 1000.0,
      fade_out: 0.1,
      shadow_multiplier: 10.0,
      debug_paint_cascades: false,
    },
    debug: {
      empty_shapes: true,
      shadow: false,
      lighting: false,
      physics: false,
      grid: true,
      culling: true,
    },
    camera: {
      fovy: Radians.fromDegrees(45.0),
      znear: 0.1,
      zfar: 1000.0,
    },
    audio: {
      volume: 0.5,
    },
  },
  scene: {
    prefabs: {},
    models: {},
    trimeshes: {},
    textures: {},
    terrains: {},
    heightfields: {},
    sprites: {},
    fonts: {},
    navmeshes: {},
    particles: {},
    animations: {},
    dynamic_backgrounds: {},
    hdrs: {},
    audio: {},
    animation_state: {},
  },
};

const store = new Store(defaultLevel, defaultEditor);
const renderers = ['level', 'vfx'] as const;
const LevelManager = createRenderer<Message, Store>(store, renderers);
export const sendToLevel = LevelManager.senders['level'];
export const sendToVfx = LevelManager.senders['vfx'];
export const LevelBus = LevelManager.buses['level'];
export const VfxBus = LevelManager.buses['vfx'];
export const LevelProvider = LevelManager.Provider;
export const MockProvider = LevelManager.MockProvider;
export const useLevelData = LevelManager.useRenderer;
export type Sender = RenderSender<Message>;
